@import 'src/assets/scss/variables';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: $xs) {
  .header {
    display: block;

    button {
      margin: 20px 0;
    }
  }
}