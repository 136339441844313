@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .incomeTitle {
    padding: 0 30px 2px 30px;
  }
  .aploadImageTitle {
    padding: 30px 30px 2px 30px;
  }
  .text {
    padding: 10px 30px 2px 30px;
  }
  .imageUrlsBox {
    padding: 40px 30px;
  }
  .documentUrlsBox {
    border-top: 1px solid $additional-light-grey-2;
    padding: 40px 30px;
    .iconVerify {
      fill: $additional-white;
      stroke: $primary-orange;
      width: 24px;
      height: 24px;
      margin: 0px 8px -5px 0px;
    }

    .goBack {
      display: inline;
      .goBackLink {
        text-decoration: none;
        color: $primary-blue;
      }
    }

    .info {
      margin-bottom: 30px;
    }
  }
}
