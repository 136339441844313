@import 'src/assets/scss/variables';

.filters {
  margin-top: 20px;
  border-radius: 24px;
  background: $additional-white;
  box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.1);
  padding: 40px 32px 45px 18px;
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
  }
}

.tableTotal{
  background: $additional-white;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.actions {
  display: flex;
  justify-content: flex-end;

  Button {
    margin-left: 20px !important;
  }
}
