@import '../../../assets/scss/variables';

.detectInformation {
  width: 100%;
  margin-bottom: 22px;
  .form {
    width: 100%;
  }
  .group {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    .button {
      cursor: pointer;
      border: 1px solid $primary-blue;
      border-radius: 20px;
      padding: 20px 20px 20px 16px;
      background-color: rgba($primary-blue-1, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrap {
        border-left: 3px solid $primary-blue;
        padding-left: 14px;
      }
      .activeBorder {
        border-left: 3px solid $secondary-hover-or;
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .desc {
        color: $additional-grey-2;
      }
      .tenantsIcon {
        width: 24px;
        height: 24px;
        stroke: $primary-blue;
        fill: white;
        margin-right: 13px;
      }
      .active {
        stroke: $secondary-hover-or;
      }
    }
    .radio {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      align-items: flex-end;
      span {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .detectInformation .group {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: $sm) {
  .detectInformation .title {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
  }
}
