@import 'src/assets/scss/variables';

.listings {
  display: flex;
  flex-direction: column;
  .items {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.actionsWrap {
  width: 100%;
  background: $additional-white;
  border-radius: 20px;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
  .links {
    display: flex;
    gap: 24px;
    margin-top: 14px;
    .link {
      cursor: pointer;
      padding: 10px 0;
      color: $additional-grey-3;
    }
    .activeTab {
      border-bottom: 2px solid $primary-blue;
      color: $primary-black;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    .buttons {
      display: flex;
      gap: 15px;

      p {
        white-space: nowrap;
      }
    }
  }
}

.paggination {
  margin-bottom: 140px;
}

.notFound {
  background: $additional-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  border-radius: 20px;
  margin-bottom: 20px;
}
@media only screen and (min-width: $md) {
  .filter{
    display: none;
  }
}
@media only screen and (max-width: $md) {
  .actionsWrap {
    margin-bottom: 0px;
    padding:20px;
    position: relative;
    .actions {
      H4{
        font-size: 20px;
      }
      .buttons {
        margin-left: 10px;
        button {
          width: auto !important;
          padding: 10px 10px !important;
        }
      }
    }
    .links{
      display: none;
      z-index: 1;
      gap: 24px;
      margin-top: 14px;
      border: 2px solid $primary-blue;
      padding: 10px;
      border-radius: 10px;
      position: absolute;
      right: 0px;
      top: 125px;
      background: #fff;
    }
    .filterLinks{
      display: inline-block !important;
    }
    
  }
  .filter{
    display: flex;
    justify-content: flex-end;
    .filterItems{
      display: flex;
      padding: 10px;
      background: #fff;
      border-radius: 12px;
      margin: 10px 0px;
      box-shadow: 0 6px 58px 0 #c4cbd61a;
      color: #717785;
      .filterIcon{
        width: 32px;
        height: 30px;
        margin-left: 10px;
        fill: #717785;
        ;
      }
    }
  }
  .isActive{
    border: 1px solid $primary-blue;
  }
}