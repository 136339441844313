@import 'src/assets/scss/variables';

.topSide {
  width: 100%;
  .headlineBox {
    height: 48px;
    background-color: $primary-blue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 0;

    .whiteLogo {
      height: 24px;
      margin: 17px 0px 11px 14px;
    }

    .topSideText {
      display: none;
    }

    .menuButton {
      width: 24px;
      height: 24px;
      margin: 11px;
      .menuIcon {
        width: 100%;
        height: 100%;
        fill: $additional-white;
      }
    }
  }

  .unauthorisedheadlineBox {
    height: 400px;
    display: block;
    position: relative;
    background: url('../../assets/images/background-cover.png'),
    linear-gradient(44deg, #091433 -1.42%, #11245C 84.88%);
    background-size: cover;

    .whiteLogo {
      margin-top: 30px;
      margin-left: 64px;
    }

    .unauthorisedText {
      display: block;
      p {
        margin-left: 64px;
        margin-top: 78px;
        font-size: 64px;
        line-height: 76px;
        font-weight: 800;
        max-width: 577px;
        background: linear-gradient(
          90deg,
          #7fd5f9 3.26%,
          #c0eafc 28.25%,
          #fff 42.08%,
          #fff 102.15%,
          rgba(250, 253, 254, 0.04) 102.16%,
          #cceefd 102.17%,
          rgba(255, 255, 255, 0) 102.18%
        );
        color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
      }
    }
  }

  .backBox {
    height: 44px;
    width: 100%;

    .backLeft {
      padding: 0;
      background: url('../../assets/images/BackLeft.png');
      height: 44px;
    }
  }
}

.link {
  text-decoration: none;
  font-size: 14px;
  color: $additional-white;
  .svgIcon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
  }
  .icon {
    stroke: $additional-light-grey-3;
  }
  .text {
    margin-left: 8px;
  }
  .wrap {
    display: flex;
    padding: 6px;
    margin: 6px 16px;
    .icon {
      display: flex;
      align-items: center;
      height: 3em;
      transform: translateY(5%);
      stroke: $additional-white;
      fill: $primary-blue !important;
    }
  }
}

.active {
  color: $primary-blue;
  background-color: $additional-white !important;
  border-radius: 14px;
  .line {
    display: flex;
  }
  .wrap {
    background: $additional-white;
    border-radius: 10px;
    .icon {
      display: flex;
      align-items: center;
      height: 3em;
      transform: translateY(5%);
      stroke: $primary-blue;
      fill: $additional-white !important;
    }
  }
}

.block {
  background-color: $primary-blue;
  right: 0;
  margin-left: auto;
  inset-inline-start: 0;
  inset-inline-end: 0;
  overflow: hidden;
  max-width: 0;
  width: 0;
  transition: max-width 0.3s ease-in-out;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: $additional-white;
  z-index: -1;
}
.hided {
  width: 0;
  height: 0;
}
.back {
  background: rgba(#222222, 0.2);
  position: fixed;
  top: 49px;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.isOpen {
  max-height: calc(100vh - 60px);
  height: 100%;
  width: 100%;
  max-width: 310px;
  overflow-y: scroll;
  margin-bottom: 50px;
  padding: 12px 0;
}

.isOpen::-webkit-scrollbar {
  width: 4px;
  padding-inline-start: 10px;
}

.isOpen::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $primary-blue;
  border-radius: 10px;
}

.isOpen::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px $additional-white;
  background-color: #ffffff;
}

.header {
  z-index: 10;
}

.hide {
  padding: 0;
  max-width: 0;
  transition: 0s ease-in-out;
}

.header {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media only screen and (max-width: $sm) {
  .topSide {
    .unauthorisedheadlineBox {
      .whiteLogo{
        margin-left: 32px;
      }
      .unauthorisedText {
        p {
          font-size: 36px;
          line-height: 46px;
          margin-top: 60px;
          margin-left: 32px;
        }
      }
    }
  }
}

@media only screen and (min-width: $md) {
  .topSide {
    display: none;
  }

  .signInRight {
    padding-top: 160px;
  }
}

@media only screen and (max-width: $md) {
  .signInLeft {
    display: none;
  }

  .signInRight {
    padding-top: 20px;

    .headlineBox {
      H3 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
      }

      p {
        font-size: 15px;
        color: $additional-grey-2;
        font-family: montserrat-regular;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}
