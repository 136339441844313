@import '../../../../assets/scss/variables';

.propertySwitcher {
  margin-bottom: 20px;
  .form {
    width: 100%;
  }
  .group {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    .button {
      cursor: pointer;
      border: none;
      border-radius: 20px;
      padding: 20px 14px;
      background-color: rgba(var(--secondary-hover-bl), 0.2);
      display: flex;
      .wrap {
      }
      .activeBorder {
        border-left: 3px solid $secondary-hover-or;
      }
      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      .desc {
        color: $additional-grey-2;
        line-height: 22px;
        text-align: left;
      }
      .tenantsIcon {
        width: 24px;
        height: 24px;
        stroke: $primary-blue;
        margin-right: 13px;
      }
      .active {
        stroke: $secondary-hover-or;
      }
    }
    .radio {
      display: flex;
      justify-content: flex-end;
      margin: 0 12px 0 0;
      align-items: flex-end;
      span {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .propertySwitcher .group {
    display: flex;
  }
}
