@import '../../../assets/scss/variables';

.personalInfo {
  margin-top: 30px;
  .title {
    margin-bottom: 15px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

@media only screen and (max-width: $md) {
  .personalInfo .inputBox .grid {
    grid-template-columns: 1fr;
  }
}
