@import '../../assets/scss/_variables';

.protectedRoute {
  display: flex;
  background: $secondary-background;
}

.logoWrap{
  height: 30px;
  .logo {
    cursor: pointer;
    width: 90px;
  }
  .collapsedLogo{
    width: 40px;
  }
}

.container {
  width: 100%;
  padding: 20px;
  height: 100vh;
  overflow-y: auto;
}

.suport {
  width: 100px;
}

.header {
  background-color: inherit;
  display: flex;
  justify-content: flex-start;
  height: 48px;
  .menuButton {
    //padding: 0 12px;
    margin-left: 12px;
    .menuIcon {
      width: 20px;
      height: 20px;
    }
  }
  .fullNameButton {
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 0 17px;
    .firstCharacter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: $primary-blue;
      color: $additional-white;
      margin-right: 13px;
    }
  }
  .button {
    background: $additional-white;
    border: none;
    padding: 0;
    border-radius: 15px;
    cursor: pointer;
    height: 100%;
  }
  .collapsedButton {
    width: 40px;
    overflow: hidden;
  }
}

.content {
  min-width: 300px;
  background: $additional-white;
  .link {
    text-decoration: none;
    color: $primary-black;
  }
  .active {
    color: $primary-blue;
  }
}

.wrap {
  padding: 8px 0;
}

@media only screen and (max-width: $md) {
  .protectedRoute {
    padding: 20px 16px;
    grid-template-columns: auto;
  }

  .wrapper {
    margin-top: 48px;
  }
}
