@import 'src/assets/scss/variables';

.items{
  margin-top: 20px;
  .item{
    background: $additional-white;
    margin-bottom: 11px;
    border-radius: 20px;
    border: 1px solid $additional-light-grey-4;
    padding: 8px;
    display: flex;
  }
  .imageWrap {
    margin-right: 28px;
    .image {
      width: 128px;
      height: 128px;
      border-radius: 16px;
    }
  }
  .type{
    color: $primary-orange;
    margin-bottom: 8px;
  }
  .address {
    margin: 8px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    .locationIcon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: $current-red;
    }
  }
  .info{
    margin-right: 10px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .balance{
      padding: 5px 0 10px 0;
    }
  }
}

@media only screen and (max-width: $sm) {
  .item{
    display: block !important;
    .info{
      align-items: stretch;
    }
  }
}
