@import '../../../../assets/scss/variables';

.propertyDetails {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  .desc {
    color: $additional-grey-2;
    margin: 10px 0 30px 0;
  }
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid $additional-light-grey-5;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.imageCoverBox {
  padding: 40px 0;
  border-bottom: 1px solid $additional-light-grey-5;
}

.imageUrlsBox {
  padding: 40px 0;
}
