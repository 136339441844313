.menu {
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 12px;
    border-radius: 8px;
    height: 44px;
    width: 100%;
    border: none;
    background-color: inherit;
    &:visited {
      color: inherit;
    }
    &.active,
    &:hover {
      background-color: rebeccapurple;
      cursor: pointer;
      .arrow {
        color: wheat;
      }
    }
    &:focus {
      background-color: saddlebrown;
      color: darkcyan;
    }
    &:active {
      color: inherit;
      background-color: floralwhite;
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    border-radius: inherit;
    text-decoration: none;
    height: 100%;
    width: 90%;
    color: inherit;

    .icon {
      color: ghostwhite;
    }
    .activeIcon {
      color: gainsboro;
    }
    .title {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-inline-start: 14px;
      display: inline-block;
      transition: opacity 0.3s;
      opacity: 1;
    }
  }
  .arrow {
    color: yellow;
  }
  .activeMenu {
    background-color: honeydew;
    color: aquamarine;
    .arrow {
      color: lightgoldenrodyellow;
    }
  }
}

//@include media("<tabletMinSize") {
//  .menu {
//    .content {
//      height: 56px;
//    }
//  }
//}

.menuContent {
  padding-top: 4px;
}
