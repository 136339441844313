@import 'src/assets/scss/variables';

.notificationItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  position: relative;
  padding: 0 12px;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  border: none;
  background-color: inherit;
  &:visited {
    color: $primary-blue;
  }
  &.active,
  &:hover {
    background-color: rgba($secondary-hover-bl,0.1);
    color: $primary-blue;
    cursor: pointer;
  }
  &:active {
    color: $primary-blue;
    background-color: rgba($primary-blue-1, 0.1);
  }

  .notificationCount {
    background-color: $additional-red;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #ffffff;
    line-height: initial;
  }

  .collapsedCount {
    height: 16px;
    width: 16px;
    position: absolute;
    margin: 0;
    top: 5px;
    right: 0;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      margin: 0;
    }
    .activeTitle {
      color: $primary-blue;
    }
  }

  span {
    width: 90%;
  }
}

.collapsedWrap {
  padding-left: 11px;
}

.notificationIcon {
  fill: $additional-white;
  stroke: $additional-light-grey-3;
  width: 40px !important;
  height: 30px;
}

.collapsedIcon {
  margin-bottom: 6px;
}

.withRtl {
  .title {
    padding-left: 0;
    padding-right: 14px;
  }
}
