@import 'src/assets/scss/variables';
.action {
  display: flex;
  justify-content: flex-end;
}
.inputBox {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $additional-white;
  border-radius: 20px;
  min-height: 400px;
  text-align: center;
  margin-top: 16px;
}

@media only screen and (max-width: $sm) {
  .inputBox {
    margin-bottom: 0px;
    .grid {
      grid-template-columns: 1fr;
      margin-bottom: 16px;
    }
  }
}
