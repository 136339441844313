@import '../../../../assets/scss/variables';

.owners {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
  background: $additional-white;
  padding: 24px 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: $sm) {
  .owners {
    grid-template-columns: 1fr;
  }
}
