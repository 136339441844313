@import '../../../assets/scss/variables';

.userInfo {
  border-bottom: 1.4px solid $additional-light-grey-4;
  padding-bottom: 20px;
  .desc {
    color: $additional-grey-2;
    margin: 10px 0 40px 0;
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 45px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }

  .emails {
    display: grid;
    grid-gap: 30px;
    margin-top: 30px;
    .emailWrap {
      position: relative;
      .closeIcon {
        position: absolute;
        right: 10px;
        width: 14px;
        height: 14px;
        top: 15px;
        fill: $additional-light-grey-3;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .userInfo .inputBox .grid {
    grid-template-columns: 1fr;
  }
}
