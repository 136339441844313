@import '../../assets/scss/variables';

.loginPage {
  height: 100vh;

  .signInLeft {
    height: 100%;
    background: url('../../assets/images/background-cover.png'), linear-gradient(44deg, #091433 -1.42%, #11245c 84.88%);
    background-size: cover;
    background-position: left;
    .content {
      height: 100%;
      padding: 80px 52px;

      .headlineBox {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 263px;
        p {
          font-size: 80px;
          line-height: 96px;
          font-weight: 800;
          max-width: 577px;
          background: linear-gradient(
            90deg,
            #7fd5f9 3.26%,
            #c0eafc 28.25%,
            #fff 42.08%,
            #fff 102.15%,
            rgba(250, 253, 254, 0.04) 102.16%,
            #cceefd 102.17%,
            rgba(255, 255, 255, 0) 102.18%
          );
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
        }
        a {
          text-decoration: none;
          color: $additional-grey-1;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .signInRight {
    padding-top: 160px;
    // height: 100%;
    width: 50%;
    overflow-y: auto;
    margin: 0 auto;
    .container {
      margin: 0 auto;
      overflow: hidden;
      padding: 0 20px;
      max-width: 460px;
    }

    .termsAndConditionsBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
      margin-top: 14px;

      a {
        text-decoration: none;
        color: #000000;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .headlineBox {
      display: flex;
      flex-direction: column;
      gap: 11px;
    }
    .title {
      color: $primary-orange;
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding-top: 45px;
      padding-bottom: 35px;
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
      }
    }

    .forgotBox {
      display: flex;
      flex-direction: column;
      gap: 50px;
      padding-top: 45px;
      padding-bottom: 20px;
    }

    .codeInputBox {
      padding: 44px 0 50px;
    }

    .dontGetCodeBox {
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .resend {
        cursor: pointer;
      }
    }

    .countdownBox {
      justify-content: end;
    }

    .forgotLink {
      display: flex;
      justify-content: flex-end;

      a {
        color: #2f5de4;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .buttonBox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding-top: 50px;
      padding-bottom: 50px;
      gap: 16px;

      p {
        padding: 4px;
      }
    }

    .createAccountBox {
      a {
        color: $primary-orange;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .createPasswordBox {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding-top: 45px;
      padding-bottom: 20px;

      button {
        margin-top: 10px;
      }
    }
  }
}

.registration {
  max-width: 422px;
  padding-top: 70px !important;
}

.redText {
  color: red !important;
}

.confirmAccount {
  padding-top: 135px;
}

.topSide {
  .headlineBox {
    height: 46px;
    background-color: $primary-blue;

    .whiteLogo {
      width: 128px;
      height: 24px;
      margin-top: 11px;
      margin-left: 14px;
    }
  }

  .backBox {
    height: 44px;
    width: 100%;

    .backLeft {
      padding: 0px;
      background: url('../../assets/images/BackLeft.png');
      height: 44px;
    }
  }
}

@media only screen and (max-width: $md) {
  .loginPage {
    .signInLeft {
      display: none;
      .content {
        padding: 70px 35px;
      }
    }

    .signInRight {
      padding-top: 0px;
      .container {
        .inputBox .grid {
          grid-template-columns: 1fr;
          grid-gap: 32px;
        }
      }
    }
  }

  .loginPage {
    display: none;
  }
}

@media only screen and (max-width: $sm) {
  .signInLeft {
    display: none;
  }
  .signInRight {
    .container {
      padding: 0px !important;

      .headlineBox h3 {
        font-size: 22px;
      }
    }
  }
}
