@import '../../../../assets/scss/variables';

.groupInfo {
  margin-bottom: 20px;
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
  .description {
    margin-bottom: 19px;
  }
}

@media only screen and (max-width: $sm) {
  .groupInfo .inputBox .grid {
    grid-template-columns: 1fr;
    grid-gap: 26px;
  }
}