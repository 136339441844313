@import 'src/assets/scss/variables';

.lease {
  display: flex;
  flex-direction: column;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.notFound {
  background: $additional-white;
  width: 100%;
  min-height: 400px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

@media only screen and (max-width: $sm) {
  .header {
    h3 {
      font-size: 20px;
    }
  }
  .leaseTabel {
    display: none;
  }
}
