@import 'src/assets/scss/variables';

.unitsList {
  display: flex;
  flex-direction: column;
  .desc {
    margin-bottom: 30px;
  }
  .notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $additional-white;
    border-radius: 12px;
    padding: 100px 0;
  }
}

@media only screen and (max-width: $sm) {
  .unitsList H3 {
    font-size: 28px;
  }
}
