@import 'src/assets/scss/variables';

.documents {
  background: $additional-white;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 30px 24px;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.102);
  .desc {
    color: $additional-grey-2;
    margin: 4px 0 20px 0;
  }
}
