@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .payInfo {
    border-top: 2px solid $additional-light-grey-2;
    margin: 30px;
    padding-top: 10px;
    .text {
      padding: 10px 0;
    }
  }
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .payTitle {
    padding: 10px 30px 10px 30px;
  }
  .info {
    padding: 0 30px 20px 30px;
  }
  .price {
    padding: 0 30px;
    color: $primary-orange;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 12px;
    .checkboxGrid {
      display: flex;
      align-items: flex-start;
      margin-top: 9px;
      .saveText {
        color: $additional-grey-3;
        margin-left: 12px;
        max-width: 600px;
      }
    }
    .privacyPolicy {
      margin: -20px 0 9px 38px;
    }
    .privacyPolicyBtn {
      margin-bottom: 40px;
    }

    .card {
      border: 1px solid $additional-light-grey-2;
      padding: 15px 24px;
      border-radius: 5px;
      background-color: $additional-light-grey-1;
    }
    .invalidCard {
      border: 1px solid $additional-red;
      padding: 15px 24px;
      border-radius: 10px;
      background-color: $additional-light-grey-1;
    }
    .cardInvalid {
      // margin-top: 5px;
      color: $additional-red;
    }
  }
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .grid {
      grid-template-columns: 1fr;
    }
    .cardDate {
      grid-template-columns: 1fr;
    }
    .cardCreditNumber {
      grid-template-columns: 1fr;
    }
  }
}
.goBackLink {
  text-decoration: none;
  color: $primary-blue;
}
