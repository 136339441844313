@import '../../../assets/scss/variables';

.container {
  .stepCalc {
    color: $additional-white;
    margin-bottom: 33px;
  }
  .progressBarContainer {
    .activeStepLabel {
      color: $additional-white;
    }

    .passiveStepLabel {
      color: $additional-grey-3;
    }
    .stepLabel {
      color: $additional-white;
      font-weight: bold;
    }
  }

  @media (max-width: $md) {
    .progressBarContainer {
      overflow-x: auto;
      position: relative;
      .stepper {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        align-items: flex-start;
        .step {
          .stepLabel {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              svg {
                width: 1.5em;
                height: 1.5em;
              }
            }
          }
          span {
            margin: 0 auto;
            display: contents;
          }
        }
        div {
          span {
            width: 80px;
            margin-top: 20px;
            border-color: $additional-white;
          }
        }
      }
    }
    .stepCalc {
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 100px;
      left: 0;
      right: 0;
      margin: o auto;
    }
  }
}
.disabledStep {
  color: $additional-light-grey-5 !important;
}
