@import '../../assets/scss/variables';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteComfirmationModal {
  position: relative;
  max-width: 573px;
  width: 100%;
  min-height: 298px;
  border-radius: 20px;
  background-color: $additional-white;
  padding: 20px;

  &:focus {
    outline: none;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 14px;
    height: 14px;
  }

  .header {
    height: 40px;
    border-bottom: 1px solid $additional-light-grey-5;
    margin-bottom: 20px;
    text-align: center;

    .title {
      color: $primary-orange;
      font-size: 30px;
    }
  }

  .content {
    color: $primary-black;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 73px;

    button {
      width: 220px !important;
    }
  }
}
