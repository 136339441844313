@import 'src/assets/scss/_variables.scss';
@import 'src/assets/fonts/fonts';

body {
  background-color: $secondary-background !important;
}

p {
  margin: 0;
}

img{
  cursor: pointer;
}