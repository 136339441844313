@import 'src/assets/scss/variables';

.viewListing {
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }
  }

  .content {
    background: $additional-white;
    padding: 30px;
    border-radius: 20px;
    .aplicationCount {
      color: $primary-orange;
      margin-top: 20px;
    }
    .label {
      margin: 16px 0 18px 0;
    }
    .desc {
      color: $additional-grey-3;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
  .gallery {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    .rightColumn {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    .leftColumn {
      max-height: 452px;
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-5;
    height: 40px;
    .item {
      color: $additional-grey-3;
    }
  }
}

.slide {
  border-bottom: 1px solid $additional-light-grey-5;
  padding-bottom: 120px;
}

.property {
  border: 1px solid $additional-light-grey-5;
  border-radius: 20px;
  padding: 6px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  .galleryImage {
    width: 128px;
    object-fit: contain;
    border-radius: 20px;
  }
  .info {
    height: 100%;
    margin-left: 19px;
    margin-bottom: auto;
    width: 100%;
    .address {
      margin: 8px 0 23px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $current-red;
      }
    }
    .monthlyRent {
      color: $primary-orange;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
    }
    .seeDetails {
      max-width: 112px;
      margin-right: 18px;
    }
  }
}

@media only screen and (max-width: $md) {
  .viewListing {
    .actions {
      display: block;
    }
    .buttons {
      margin-top: 10px;
    }
    .content {
      padding: 10px;
    }
  }
}
