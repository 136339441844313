@import '../../assets/scss/variables';

.createHelp {
  .title {
    margin-bottom: 20px;
  }

  .container{
    background: $additional-white;
    border-radius: 20px;
    padding: 30px;

  .descTitle {
    margin-bottom: 20px;
  }

  .desc {
    margin: 20px 0;
  }

  .documentBox {
    padding: 40px 30px 0 0;

    .info {
      margin-bottom: 30px;
    }
  }
  .btn {
    margin-top: 20px;
  }
  }
}

@media only screen and (max-width: $md) {
  .createHelp {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }
}
