@import 'src/assets/scss/variables';
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px white inset;
  height: 16px;
}
.input-wrapper {
  background-color: $additional-light-grey-1;
  height: 48px !important;

  .show-password,
  .hide-password {
    fill: #7d8592;
    stroke: #7d8592;
    width: 24px;
    height: 24px;
  }

  &.full-size {
    width: 100%;
  }

  .Mui-error {
    margin-top: 0 !important;
    .MuiInputBase-input {
      color: $additional-red;
    }

    &.Mui-focused {
      .MuiInputBase-input {
        color: $additional-grey-2;
      }
    }

    color: $additional-red;

    &.MuiInputLabel-root {
      color: $additional-red;
    }

    &.MuiFormHelperText-root {
      color: $additional-red;
      line-height: 16px;
    }

    &.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid $additional-red !important;
    }
  }

  .MuiFormHelperText-root {
    font-size: 12px;
    margin-top: 6px;
    margin-left: 0;
    line-height: 20px;
  }

  .MuiInputLabel-root {
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(180deg, rgba(252, 252, 252, 0) 0%, rgba(252, 252, 252, 0) 47.76%, #fcfcfc 50.17%, #fcfcfc 100%);
    padding: 0px 4px;
    color: $additional-grey-2;

    &.Mui-focused {
      color: $primary-blue;
    }
  }

  .plugin {
    color: red;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    color: $additional-grey-2;

    &::placeholder {
      opacity: 1;
      color: $additional-light-grey-4;
      font-weight: 400;
    }
  }

  .Mui-focused .MuiOutlinedInput-input {
    &::placeholder {
      color: $additional-grey-2;
      font-weight: 500;
    }
  }

  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
  .MuiInputBase-root {
    height: 48px;
  }

  .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    margin: 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $additional-light-grey-2;
    padding: 0 10px;

    legend {
      max-width: 0.01px;

      span {
        font-size: 12px;
      }
    }
  }

  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $primary-blue;
  }

  .select-input {
    margin: 0;
    padding: 0;

    .MuiInputBase-input {
      color: $neutral-900;
    }

    .MuiInputBase-root {
      .MuiOutlinedInput-notchedOutline {
        border: none !important;
      }
    }

    .MuiSelect-icon {
      color: $additional-light-grey-4;
    }
  }
}

.textarea-wrapper {
  height: 100% !important;
  .MuiInputBase-root {
    height: 100% !important;
  }
  .MuiFormHelperText-root {
    position: absolute;
    bottom: -16px;
  }
}

.autocomplete-wrapper {
  .MuiInputBase-root {
    height: auto;
  }
  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $primary-orange;
  }
  .MuiChip-deletableColorDefault {
    color: $additional-white;
    background: $primary-blue;
    border-radius: 8px;
    svg {
      border-radius: 20px;
      background: $additional-white;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      color: $primary-blue;
      fill: $primary-blue;
    }
  }
}

.input-wrapper input[type="password"]::-ms-reveal,
.input-wrapper input[type="password"]::-ms-clear {
  display: none;
}
