@import 'src/assets/scss/variables';

.MuiDateCalendar-root {
  .MuiDayCalendar-weekContainer {
    .MuiButtonBase-root.Mui-selected {
      background-color: $primary-blue;
    }
  }
}

.MuiYearCalendar-root {
  .MuiPickersYear-root {
    .MuiPickersYear-yearButton.Mui-selected {
      background-color: $primary-blue;
    }
  }
}

.MuiInputBase-root {
  cursor: pointer !important;
  height: 48px;

  input {
    cursor: pointer;
  }
}

.datepicker-wrapper {
  background-color: $additional-light-grey-1;
  height: 48px;

  &.full-size {
    width: 100%;
  }

  .Mui-error {
    .MuiInputBase-input {
      color: $additional-red;
    }

    &.Mui-focused {
      .MuiInputBase-input {
        color: $additional-grey-2;
      }
    }

    &.MuiFormHelperText-root {
      color: $additional-red;
    }

    color: $additional-red;
    margin-top: 0 !important;

    &.MuiInputLabel-root {
      color: $additional-red;
    }

    &.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid $additional-red;
    }
  }

  .MuiFormHelperText-root {
    font-size: 12px;
    margin-top: 6px;
    margin-left: 0;
    line-height: 20px;
  }

  .MuiInputLabel-root {
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(180deg, rgba(252, 252, 252, 0) 0%, rgba(252, 252, 252, 0) 47.76%, #fcfcfc 50.17%, #fcfcfc 100%);
    padding: 0px 4px;
    color: $additional-grey-2;

    &.Mui-focused {
      color: $primary-blue;
    }
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    color: $additional-grey-2;

    &::placeholder {
      opacity: 1;
      color: $additional-light-grey-4;
      font-weight: 400;
    }
  }

  .Mui-focused .MuiOutlinedInput-input {
    &::placeholder {
      color: $additional-grey-2;
      font-weight: 500;
    }
  }

  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }

  .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
    margin: 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid $additional-light-grey-2;
    padding: 0 10px;

    legend {
      max-width: 0.01px;

      span {
        font-size: 12px;
      }
    }
  }

  .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid $primary-blue;
  }

  .MuiIconButton-root {
    margin-right: 0;
  }
}
