@import 'src/assets/scss/variables';

.imageUploader {
  display: flex;
  align-items: center;
  .uploadButton {
    width: 100px;
    min-width: 100px;
    height: 100px;
    background: $additional-white;
    padding: 0;
    cursor: pointer;
    outline: inherit;
    color: $additional-grey-2;
    border: 1px dashed $additional-light-grey-2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 16px;
    .desc {
      font-size: 8px;
      line-height: 10px;
    }
    .iconWrap {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $additional-light-grey-2;
      margin-bottom: 8px;
      border-radius: 50%;

      .uploadIcon {
        width: 10px;
        height: 10px;
        fill: $additional-grey-3;
      }
    }
    .btnErrorText {
      color: $additional-red;
      text-align: center;
      font-size: 13px;
      padding: 6px;
    }
    .errorIcon {
      fill: $additional-red;
      stroke: $additional-white;
      width: 24px;
      height: 24px;
      background-color: $additional-red;
      padding: 4px;
      border-radius: 50%;
    }
  }
  .bigUploadButton {
    width: 150px;
    min-width: 150px;
    height: 150px;
    .fileInput {
      min-height: 190px;
    }
  }
  .hasError {
    border: 1px dashed $additional-red;
  }
}

.disabled {
  background: $additional-grey-3;
  opacity: 0.2;
  cursor: none;
}

.images {
  display: flex;
  flex-wrap: wrap;
  .image {
    border: 1px dashed $primary-orange;
    margin-left: 17px;
    position: relative;
    object-fit: contain;
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .close {
      width: 22px;
      height: 22px;
      position: absolute;
      right: -12px;
      top: -10px;
      cursor: pointer;
    }
  }
}

.documents {
  display: flex;
  flex-wrap: wrap;
  .document {
    border-radius: 10px;
    border: 1px dashed $primary-orange;
    display: flex;
    margin-left: 17px;
    position: relative;
    object-fit: contain;
    margin-bottom: 16px;
    width: 150px;
    height: 150px;

    .pdfDocumentContainer {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;

      .file {
        text-align: center;
        border-radius: 10px;
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
      }
      .fileBlock {
        position: absolute;
        background-color: rgba(128, 128, 128, 0.362);
        width: 100%;
        height: 150px;
        top: 0;
        border-radius: 10px;
      }

      .fileName {
        position: absolute;
        bottom: -1px;
        background-color: $additional-white;
        border-radius: 10px;
        width: 100%;

        .docInfo {
          text-align: center;
          width: 100%;
          height: 70px;
          flex: 0 0 170px;
          word-wrap: break-word;
          display: inline-block;
          padding: 15px 2px 0 2px;
        }
      }
    }

    .docIcon {
      padding: 10px;
      border-right: 1px solid grey;
    }

    .close {
      width: 22px;
      height: 22px;
      position: absolute;
      right: -12px;
      top: -10px;
      cursor: pointer;
    }
  }

  .image {
    border: 1px dashed $primary-orange;
    border-radius: 10px;
    margin-left: 17px;
    position: relative;
    object-fit: contain;
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    .img {
      overflow: hidden;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .close {
      width: 22px;
      height: 22px;
      position: absolute;
      right: -12px;
      top: -10px;
      cursor: pointer;
    }
  }
}

.bigImages {
  .image {
    width: 150px;
    height: 150px;
  }
}

.fileInput {
  position: absolute;
  width: 100%;
  height: 150px;
  cursor: pointer;
  input {
    cursor: pointer;
  }
}

.error {
  color: $additional-red;
  margin-left: 17px;
}

@media only screen and (max-width: $sm) {
  .imageUploader {
    display: block;
    .bigUploadButton {
      width: 100%;
    }
    .uploadButton {
      width: 100%;
    }
  }

  .bigImages .image {
    width: 100%;
    margin-left: 0px;
  }
}
