.popover {
  height: 100%;
  .button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    height: 100%;
  }
}

.content {
  margin-top: 5px;
}
