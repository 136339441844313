@import 'src/assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 0 0 10px 0;
  }
  .desc {
    padding: 20px 0;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .addPhoneGroup {
      margin-top: 60px;
    }
  }
  .addPhone {
    width: 49% !important;
    height: 47px !important;
    padding: 16px 48px !important;
    border-radius: 6px !important;
  }
  .phones {
    .phonesWrap {
      border-top: 1px solid $additional-light-grey-2;
      margin: 30px 0;
      position: relative;
      .closeBox {
        .titlePhone {
          margin: 30px 0 9px 0;
        }
      }
      .closeIcon {
        position: absolute;
        right: 0;
        width: 14px;
        height: 14px;
        top: 28px;
        fill: $additional-light-grey-3;
        cursor: pointer;
      }
    }
  }
}
.signUp {
  color: $primary-blue;
  margin-left: 10px;
  cursor: pointer;
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .grid {
      grid-template-columns: 1fr;
    }
  }
}
