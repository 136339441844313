@import 'src/assets/scss/variables';

.card {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 20px 30px;
  margin-bottom: 16px;
  width: 100%;
  background-color: $additional-white;
  box-shadow: 0 6px 58px 0 #c4cbd61a;
  .button {
    max-width: 62px;
    bottom: 0;
  }
}

.wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.extendableContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.isOpen {
  max-height: 5000px;
  transition: max-height 0.6s ease-in-out;
}

.arrowIcon {
  width: 24px;
  height: 24px;
  stroke: $additional-grey-3;
  margin-top: 3px;
}

.active {
  transform: rotate(180deg);
  stroke: $primary-blue;
  margin-bottom: 3px;
}

@media only screen and (max-width: $sm) {
  .wrap {
    display: grid;
    grid-auto-columns: 1fr;
    .button {
      margin: 10px 7px 10px auto;
    }
  }

  .card {
    padding: 20px 15px;
  }
}
