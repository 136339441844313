@import 'src/assets/scss/variables';

.card {
  background-color: $additional-white;
  box-shadow: 0 6px 58px 0 #c4cbd61a;
  padding: 20px 30px;
  border-radius: 20px;
  margin-bottom: 16px;
  width: 100%;

  .cardTitle {
    display: flex;
    justify-content: space-between;

    .viewAllButton {
      font-size: 14px;
      background: none;
      border: none;
      float: right;
      color: $secondary-hover-or;
      cursor: pointer;
    }
  }
  .tasks {
    background-color: $additional-white;
    border-radius: 24px;
    padding: 0px 20px;
    .tabsWrap {
      padding-left: 0;
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      .tabs {
        .indicator {
          background-color: $primary-blue;
        }
      }
    }
    .taskItem {
      padding: 16px;
      border-radius: 8px;
      border: 1.6px solid var(--additional-light-grey-5);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 16px;
      .taskItemText {
        width: 60%;
        padding-left: 14px;
        border-left: 3px solid $secondary-hover-or;
        border-radius: 0px 33px 33px 0px;
        margin-right: 50px;
        .B3 {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .card {
    .tasks {
      .tabsWrap {
        max-width: 320px;
      }

      .taskItem {
        Button {
          width: auto !important;
        }
      }
    }
  }
}

@media only screen and (max-width: $xs) {
  .card {
    .tasks {
      .taskItem {
        display: grid;
        grid-template-rows: auto auto;
        white-space: pre-wrap;
        text-overflow: clip;

        .B3 {
          white-space: normal !important;
        }

        Button {
          width: 120px !important;
        }
      }
    }
  }
}