@import 'src/assets/scss/variables';

@media only screen and (max-width: $sm) {
  .leaseCard {
    display: block;
    margin-top: 10px;
    border: 1px solid $secondary-hover-bl;
    .cardItem {
      border-bottom: 1px solid $additional-grey-4;
      padding: 10px 10px;
      cursor: pointer;
      .cardItemtitle {
        color: $additional-grey-3;
      }
      .cardItemInfo {
        font-size: 15px;
        font-weight: 600;
        color: $additional-grey-1;
        line-height: 22px;
      }
    }
    .lastItem {
      border-bottom: none;
    }
    .address {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
    }
  }
}

@media only screen and (min-width: $sm) {
  .leaseCard {
    display: none;
  }
}
