@import 'src/assets/scss/variables';

.card {
  background-color: $additional-white;
  box-shadow: 0 6px 58px 0 #c4cbd61a;
  padding: 7px 17px 7px 7px;
  border-radius: 20px;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  .imageWrap {
    margin-right: 16px;
    .image {
      width: 128px;
      height: 128px;
      border-radius: 16px;
    }
  }
  .wrap {
    display: flex;
    width: 100%;
    .info {
      .propertyName {
        white-space: pre-wrap;
        word-break: break-all;
      }
      .rent {
        margin: 8px 0;
        color: $current-red;
        display: flex;
        align-items: center;
        cursor: pointer;
        .listIcon {
          min-width: 24px;
          width: 24px;
          height: 24px;
          margin-right: 8px;
          fill: $additional-white;
          stroke: $current-red;
        }
      }
      .paymentDate {
        display: flex;
        margin-top: 10px;
        .line {
          width: 2px;
          height: 25px;
          background-color: $primary-black;
          margin: 0 10px;
        }
      }
    }
  }

  .actions {
    text-align: center;
    margin: auto;
    p {
      display: inline-block;
    }

    Button {
      margin-top: 7px;
    }
  }
}
.content {
  margin-top: 17px;
}

@media only screen and (max-width: $md) {
  .card {
    display: grid;
    grid-template-columns: 1fr;
  }

  .wrap {
    padding: 10px;
  }
}

@media only screen and (max-width: $sm) {
  .card .wrap {
    display: block;
  }
}
