@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .infoBox {
    display: flex;
    justify-content: space-between;
    .incomeTitle {
      padding: 10px 30px;
    }
    .rightInfo {
      color: $primary-orange;
      padding: 10px 30px;
    }
  }
  .text {
    padding: 10px 30px;
  }
  .subText {
    padding: 10px 30px;
  }
  .faq {
    padding: 10px 30px;
    .circleIcon {
      display: inline-flex;
      justify-content: center;
      text-align: center;
      width: 24px;
      height: 24px;
      border: 3px solid $primary-orange;
      border-radius: 50%;
      color: $primary-orange;
      font-size: 16px;
      line-height: 16px;
      font-weight: bold;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    .faqText {
      display: inline-flex;
      color: $primary-orange;
    }
  }
  .authenticateBankBtn {
    margin: 30px;
  }
  .continueeBankBtn {
    margin: 16px 30px 52px 30px;
    padding: 0 0 28px 0 !important;
  }
  .endText {
    margin: 0 30px;
  }
  .skip {
    padding: 6px 40px !important;
    border-radius: 4px !important;
    margin: 16px 30px 52px 30px;
  }
}

@media only screen and (max-width: $md) {
  .container {
    .infoBox {
      display: block;
    }
    .skip {
      width: 100%;
      margin: 0px;
    }
  }
}
