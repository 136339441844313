@import 'src/assets/scss/variables';

.sidebarContent {
  overflow-y: auto;
  flex: 1;
  padding: 0 10px;
  max-height: calc(100vh - 240px);
}

.sidebarContent::-webkit-scrollbar {
  width: 2px;
  padding-inline-start: 10px;
}

.sidebarContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $additional-white;
  border-radius: 10px;
}

.sidebarContent::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0 $primary-blue;
  background-color: #ffffff;
}
