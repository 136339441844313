@import '../../../../assets/scss/variables';

.details {
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
    background: $additional-white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .details {
    .inputBox {
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }
}
