@import '../../../../assets/scss/variables';

.utilitiesLease {
  width: 100%;
  background: $additional-white;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 30px 24px;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.102);
  .title {
    margin-bottom: 20px;
  }
  .wrap {
    display: flex;
    margin-bottom: 15px;
    .label {
      width: 100px;
      color: $additional-grey-3;
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .item {
        font-size: 13px;
        line-height: 18px;
        padding: 7px 10px;
        border-radius: 10px;
        background: $secondary-background;
      }
    }
  }
}
