@import 'src/assets/scss/variables';

.listingsCard {
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 3px 14px 0 #0000001f;
  .imageWrap {
    margin-right: 28px;
    .image {
      cursor: pointer;
      width: 212px;
      height: 216px;
      border-radius: 16px;
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .policy {
      display: flex;
      gap: 10px;
      margin-bottom: 8px;
    }
    .address {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $current-red;
      }
    }
    .price {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rent {
        color: $primary-orange;
        margin-bottom: 5px;
      }
      .availableFrom {
        color: $primary-green;
      }
    }

    .title {
      word-wrap: break-word;
      color: $secondary-hover-bl;
      max-width: 730px;
      word-break: break-all;
    }
    .buttons {
      display: grid;
      margin-top: 10px;
      grid-template-columns: 200px 200px;
      grid-gap: 12px;
    }
  }
}

@media only screen and (max-width: $md) {
  .listingsCard .content {
    flex-direction: column;
    .info {
      margin-top: 20px;
      .buttons {
        display: flex;
      }
    }
  }
}
