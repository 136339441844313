@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .vehiclesTitle {
    padding: 12px 30px 2px 30px;
  }
  .text {
    margin: 12px 30px 2px 30px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .checkboxGrid {
      margin-left: 30px;
      margin-top: 15px;
    }
    .addVehicle {
      width: 49% !important;
      height: 47px !important;
      padding: 16px 48px !important;
      border-radius: 6px !important;
    }
    .vehicle {
      .vehicleWrap {
        margin: 30px 0;
        .closeBox {
          position: relative;
          top: 10px;
          margin-bottom: 38px;
          padding-top: 50px;
          border-top: 1px solid var(--additional-light-grey-2);
          .closeIcon {
            position: absolute;
            z-index: 999;
            right: 0;
            width: 14px;
            height: 14px;
            top: 28px;
            fill: $additional-light-grey-3;
            cursor: pointer;
          }
        }
      }
      .vehicleWrap:first-of-type {
        margin-top: 2px;
      }
    }
  }

  .checkboxGrid {
    display: flex;
    align-items: flex-start;
    .vehicleText {
      color: $additional-grey-3;
      margin-left: 12px;
      max-width: 495px;
    }
    .vehicleTextUnchacked {
      padding-bottom: 30px;
    }
  }
  .addBtn {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .vehicleGroup {
    margin: 15px 30px;
  }
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .grid {
      grid-template-columns: 1fr;
    }
    .addVehicle {
      width: 100% !important;
    }
  }
}
