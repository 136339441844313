@import '../../../../assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .previousAddress {
    border-top: 1px solid $additional-light-grey-2;
    .previousAddressTitle {
      margin-top: 30px;
      margin-bottom: 7px;
    }
  }
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .addressTitle {
    padding: 10px 30px 10px 30px;
  }
  .info {
    padding: 0 30px 20px 30px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 5px 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .calendar {
      width: 50%;
    }
    .addAddress {
      width: 49% !important;
      height: 47px !important;
      padding: 16px 48px !important;
      border-radius: 6px !important;
    }
    .addresses {
      display: grid;
      .titlePhone {
        position: relative;
        top: 70px;
      }
      .additionalAddressGroup {
        .inputBox {
          padding: 10px 0;
        }
        .closeFirst {
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
        .closeBox {
          position: relative;
          margin-bottom: 50px;
          margin-top: 30px;
          .closeIcon {
            position: absolute;
            right: 0;
            width: 14px;
            height: 14px;
            top: 28px;
            fill: $additional-light-grey-3;
            cursor: pointer;
          }
          .closeIcon:first-child {
            top: -85px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .calendar {
      width: 100%;
    }
    .addAddress {
      width: 100% !important;
    }
    .grid {
      grid-template-columns: 1fr;
      grid-gap: 30px;
    }
  }
}