@import 'src/assets/scss/variables';

.createListings {
  display: flex;
  flex-direction: column;
  .infoBox {
    border-radius: 20px;
    background-color: $additional-white;
    padding: 30px;
    margin: 30px 0;
  }
}

.images {
  border-radius: 20px;
  background-color: $additional-white;
  padding: 30px;
  .desc {
    color: $additional-grey-2;
    margin: 10px 0 30px 0;
  }
  .imageCoverBox {
    border-bottom: 1px solid $additional-light-grey-5;
    padding-bottom: 30px;
  }
  .imageUrlsBox {
    padding: 20px 0;
  }
}
