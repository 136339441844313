@import '../../../assets/scss/variables';

.companyInfo {
  .desc {
    color: $additional-grey-2;
    margin-top: 10px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
  .detectInformation {
    margin-bottom: 10px;
  }
  .dbnTitle {
    margin: 20px 0 0 0;
  }
  .wrap {
    width: 100%;
  }
}
