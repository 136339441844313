@import 'src/assets/scss/variables';

.statusLine {
  display: flex;
  height: 100%;
  .line {
    min-width: 3px;
    margin: 4px 8px 4px 0;
    padding: 20px 0;
    border-top-right-radius: 33px;
    border-bottom-right-radius: 33px;
  }
  .children {
    display: flex;
    align-items: center;
  }
}
