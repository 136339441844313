@import 'src/assets/scss/variables';

.viewLease {
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;
    .buttons {
      display: flex;
      gap: 15px;
    }
  }
  .title {
    margin-bottom: 20px;
    display: flex;
    .edit {
      cursor: pointer;
      color: $primary-blue;
      border-bottom: 1px solid $primary-blue;
      width: fit-content;
      height: 20px;
      margin-left: 12px;
    }
  }
  .documents {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px 10px 0px;
    margin-bottom: 20px;
    &:last-of-type {
      border-bottom: none;
    }
    .fileBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      direction: rtl;
    }

    .image {
      border: 1.4px solid $additional-light-grey-2;
      border-radius: 4px;
      display: flex;
      margin-left: 17px;
      position: relative;
      object-fit: contain;
      width: 230px;
      height: 140px;
      margin-bottom: 16px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .pdfDocumentContainer {
      cursor: pointer;
      position: relative;
      width: 230px;
      height: 140px;
      max-width: 100%;
      // max-height: 100%;

      .file {
        text-align: center;
        border-radius: 10px;
        width: 100% !important;
        height: 100% !important;
        overflow: hidden;
      }
      .fileBlock {
        width: 230px;
        height: 140px;
        border-radius: 10px;
        text-align: center;
      }

      .fileName {
        position: absolute;
        bottom: 0;
        background-color: $additional-white;
        border-radius: 10px;
        width: 100%;

        .docInfo {
          // padding: 10px;
          padding-top: 15px;
          text-align: center;
          width: 100%;
          height: 70px;
          flex: 0 0 170px;
          word-wrap: break-word;
          display: inline-block;
          font-size: small;
        }
      }
    }

    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .viewLease {
    .header {
      display: inline;
      .buttons {
        width: 125px;
        margin-left: 65%;
      }
      h4 {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .documents {
      .image {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}
