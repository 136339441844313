@import '../../../assets/scss/variables';

.btn {
  box-shadow: none !important;
  border-radius: 10px !important;
  white-space: nowrap;
}

.facebook {
  fill: $additional-white;
  width: 14px;
}
.google {
  width: 24px;
}
