@import '../../../../assets/scss/variables';

.utilities {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  .title {
    margin-bottom: 10px;
  }
  .label {
    color: $additional-grey-1;
  }
  .desc {
    color: $additional-grey-2;
    margin-bottom: 20px;
  }
  .table {
    border: 1px solid $additional-light-grey-2;
    background: $additional-light-grey-1;
    tr:hover {
      background: transparent;
      cursor: auto;
    }
    td {
      padding: 9px 18px;
    }
  }
  .table tr:last-child td {
    border-block-end: 0;
  }
}

.tenant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trashIcon {
  width: 24px;
  height: 24px;
  fill: $additional-white;
  cursor: pointer;
}
