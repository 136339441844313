@import '../../../assets/scss/variables';

.businessInfo {
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 30px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

.checkboxGrid {
  display: flex;
  align-items: flex-start;
  .title {
    color: $additional-grey-3;
    margin-left: 12px;
    max-width: 495px;
  }
}

.bussinesTitle {
  padding: 30px 0 16px 0;
}

.desc {
  color: $additional-grey-3;
  padding-bottom: 24px;
}
