@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;

  .incomeInfo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 12px 0;
    .profile {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: $additional-grey-3;
    }
    .iconLight {
      fill: $additional-white;
      stroke: $additional-grey-3;
    }
  }

  .additionalIncome {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid $additional-light-grey-2;
    &:last-child {
      border-bottom: none;
    }
    .additionalIncomeRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
    }
  }
}
