@import '../../../assets/scss/variables';

.backButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $primary-orange;
  width: fit-content;
  .backIcon {
    height: 20px;
    width: 20px;
    margin-right: 4px;
    fill: $primary-orange;
  }
}
