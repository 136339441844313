$sidebar-width: 280px;
$sidebar-collapsed-width: 64px;

.backgroundBox {
  width: $sidebar-collapsed-width;
  min-width: $sidebar-collapsed-width;
  height: 100%;
  min-height: 100vh;
}
.asideBar {
  background-color: #fff;
  color: rebeccapurple;
  height: 100%;
  min-height: 100vh;
  width: $sidebar-width;
  min-width: $sidebar-width;
  box-shadow: 0 0 6px rgba(95, 139, 205, 0.16);
  padding: 14px 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;

  &.hovered {
    transition: width, 0.3s;
  }

  &.collapsed {
    transition: width, 0.3s;
    width: $sidebar-collapsed-width;
    min-width: $sidebar-collapsed-width;
  }
  &.active {
    position: relative;
  }

  .layout {
    flex: 1;
    height: 100%;
    position: relative;
    //margin: 10px;
    display: flex;
    flex-direction: column;
  }

  .toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0 18px 0;

    .toggleIcon {
      cursor: pointer;
      padding-right: 15px;

      &:hover {
        svg path {
          fill: rebeccapurple;
        }
      }
    }

    &.collapsed {
      justify-content: center;

      .toggleIcon {
        padding-right: 0;
      }

      .arrowIcon {
        transform: rotate(180deg);
      }
    }
  }
}
