@import 'src/assets/scss/variables';

.createLease {
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    .buttons {
      display: flex;
      gap: 15px;
      button {
        width: 100px !important;
      }
    }
  }
  .status {
    display: flex;
    gap: 12px;
    .item {
      cursor: pointer;
    }
  }
  .userCard {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: $sm) {
  .createLease {
    .header {
      display: block;
      h4 {
        font-size: 22px;
      }
      .buttons {
        justify-content: end;
        margin-right: 20px;
      }
    }
  }
  .userCard {
    display: block !important;
    text-align: center;
  }
}
