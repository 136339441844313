@import '../../../../assets/scss/variables';
.otherInformation {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  .title {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .desc {
    color: $additional-grey-2;
    margin-bottom: 20px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-top: 10px;
      padding-bottom: 10px;
      .petWeightInp {
        min-width: 135px;
      }
    }
  }
  .description {
    color: $additional-grey-2;
  }
  .detectInformation {
    border-bottom: 1px solid $additional-light-grey-5;
    padding-bottom: 20px;
  }
}
