@import 'src/assets/scss/variables';

.viewPropertyListing {
  width: 100%;
  margin-bottom: 60px;
  .backButton {
    margin-top: 32px;
  }
  .title {
    margin-bottom: 17px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;
    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
    }
  }

  .content {
    background: $additional-white;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 20px;
    .aplicationCount {
      color: $primary-orange;
      margin-top: 20px;
    }
    .label {
      margin: 16px 0 18px 0;
    }
    .desc {
      color: $additional-grey-3;
      word-wrap: break-word;
      word-break: break-all;
      margin-bottom: 10px;
    }
  }
  .gallery {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    .rightColumn {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
    }
    .leftColumn {
      max-height: 452px;
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-5;
    height: 40px;
    .item {
      color: $additional-grey-3;
    }
  }
}

.slide {
  border-bottom: 1px solid $additional-light-grey-5;
  padding-bottom: 120px;
}

.property {
  margin: 25px 0;
  display: flex;
  align-items: center;
  .galleryImage {
    width: 128px;
    object-fit: contain;
    border-radius: 20px;
  }
  .info {
    height: 100%;
    margin-left: 19px;
    margin-bottom: auto;
    width: 100%;
    .address {
      margin: 8px 0 23px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $current-red;
      }
    }
    .monthlyRent {
      color: $primary-orange;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
    }
    .seeDetails {
      max-width: 112px;
      margin-right: 18px;
    }
  }
}

.box {
  width: 100%;
  background: $additional-white;
  margin: 20px 0;
  .title {
    margin-bottom: 17px;
  }
  .wraper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .name {
      width: 100px;
      color: $additional-grey-3;
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .item {
        font-size: 13px;
        line-height: 18px;
        padding: 7px 10px;
        border-radius: 10px;
        background: $secondary-background;
      }
    }
  }
}

.tags {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.avelable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .availableFrom {
    color: $primary-green;
  }
}

.petDescription {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 20px;
}
