@import 'src/assets/scss/variables';

.profile {
  .form {
    background-color: $additional-white;
    border-radius: 20px;
    padding: 30px 10px;
    margin-top: 28px;

    .create {
      margin-top: 20px;
    }
  }

  .title {
    margin-bottom: 31px;
  }
}

.desc {
  margin-bottom: 15px !important;
}

@media only screen and (max-width: $sm) {
  .title {
    font-size: 24px !important;
  }
}
