@import "src/assets/scss/variables";

.bankAccount {
  display: flex;
  flex-direction: column;

  .button {
    margin-top: 20px;
  }

  .deleteIcon {
    width: 25px;
    height: 25px;
    fill: $additional-white;
  }

  .deleteIcon:hover {
    fill: $primary-blue;
    transition: 0.3s;
  }
}
