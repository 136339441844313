@import '../../../../assets/scss/variables';

.selectUnit {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  .desc {
    color: $additional-grey-2;
    margin: 10px 0 30px 0;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
    margin-top: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

.boxWrap {
  border-top: 1px solid $additional-light-grey-5;
  padding-top: 30px;
  .deleteIcon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: $additional-grey-3;
    display: flex;
    margin-left: auto;
  }
}

@media only screen and (max-width: $md) {
  .selectUnit .inputBox .grid {
    grid-template-columns: 1fr;
  }
}
