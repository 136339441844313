@import '../../../../assets/scss/variables';

.insurance {
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.102);
  background: $additional-white;
  padding: 30px 24px;
  border-radius: 20px;
  .desc {
    margin: 8px 0 10px 0;
  }
  .description {
    color: $additional-grey-3;
    margin-top: 10px;
  }
}
