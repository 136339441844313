@import 'src/assets/scss/variables';

.managers {
  .addManager {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  border-radius: 20px;
  margin-bottom: 30px;
  .title {
    margin-bottom: 40px;
  }
  .table {
    tr:hover {
      background: transparent;
      cursor: auto;
      border-radius: 20px;
    }
    td {
      padding: 9px 18px;
    }
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $additional-white;
  border-radius: 20px;
  min-height: 700px;
  text-align: center;
}
.applicant {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 12px;
  border-radius: 3px;
  .firstName {
    white-space: pre-wrap;
  }
}
.emptyField {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 12px;
}

.emailWrap {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 0;
  border-radius: 3px;
  .email {
    text-wrap: wrap;
  }
}

@media only screen and (max-width: $sm) {
  .managers {
    .title {
      font-size: 20px;
    }
  }
  .managerTable {
    display: none;
  }
}
