@import 'src/assets/scss/variables';

.publicListings {
  width: 100%;
}

.title {
  background: $additional-white;
  padding: 22px 40px;
  margin: 22px 0;
  border-radius: 12px;
  box-shadow: 0 6px 58px 0 #c4cbd61a;
}

.paggination {
  margin: 20px 0 140px 0;
}

.notFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $additional-white;
  border-radius: 12px;
  padding: 100px 0;
}
