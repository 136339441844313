@import 'src/assets/scss/variables';

.tenantPersonalInfo {
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 0;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .addPhoneGroup {
      margin-top: 60px;
    }
  }
  .phones {
    .phonesWrap {
      border-top: 1px solid $additional-light-grey-2;
      margin: 30px 0;
      position: relative;

      .closeBox {
        .titlePhone {
          margin: 30px 0 9px 0;
        }
      }

      .closeIcon {
        position: absolute;
        right: 0;
        width: 14px;
        height: 14px;
        top: 28px;
        fill: $additional-light-grey-3;
        cursor: pointer;
      }
    }
  }
}

.addPhone {
  width: 49% !important;
  height: 47px !important;
  padding: 16px 48px !important;
  border-radius: 6px !important;
}

@media only screen and (max-width: $xs) {
  .addPhone {
    width: 100% !important;
  }
}