@import 'src/assets/scss/variables';

.chooseProperty {
  .desc {
    color: $additional-grey-2;
    margin-bottom: 10px;
  }
  .notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $additional-white;
    border-radius: 12px;
    padding: 100px 0;
  }
}
