@import 'src/assets/scss/variables';

.tenants {
  border-radius: 20px;
  margin-bottom: 30px;
  .addTenant {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
    .title {
      margin-bottom: 40px;
    }
  }
  .table {
    tr:hover {
      background: transparent;
      cursor: auto;
      border-radius: 20px;
    }
    td {
      padding: 9px 18px;
    }
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emptyContainer {
  background: $additional-white;
  width: 100%;
  min-height: 400px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.item {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: left;
  padding: 20px 12px;
  .firstName {
    white-space: pre-wrap;
  }
}

.emptyField {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px 12px;
}

.emailWrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 12px;
  border-radius: 3px;
  padding: 10px;
  .email {
    text-wrap: wrap;
  }
}

@media only screen and (max-width: $sm) {
  .tenants {
    .addTenant .title {
      font-size: 20px;
    }

    .tenantTable {
      display: none;
    }
  }
  
}
