@import 'src/assets/scss/variables';

@media only screen and (max-width: $sm) {
  .bankAccountCard {
    position: relative;
    display: block;
    margin-top: 10px;
    border: 1px solid $primary-blue;
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .cardItem {
      border-bottom: 1px solid $additional-grey-4;
      padding: 10px 10px;
      cursor: pointer;
      .cardItemtitle {
        color: $additional-grey-3;
      }
      .cardItemInfo {
        font-size: 15px;
        font-weight: 600;
        color: $additional-grey-1;
        line-height: 22px;
      }
    }
    .lastItem {
      border-bottom: none;
    }
    .address {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 22px;
        height: 22px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
    }
  }

  .bankAccountCard:first-child {
    margin-top: 30px;
  }

  .bankAccountCard {
    .cardItem:last-child {
      border-bottom: none;
      padding-bottomsignInRight: 0px;
    }
  }
}

@media only screen and (min-width: $sm) {
  .bankAccountCard {
    display: none;
  }
}
