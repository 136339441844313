@import 'src/assets/scss/variables';

.personlInformation {
  border-radius: 20px;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }

  .personInfoForm {
    padding: 30px 30px 10px 30px;
  }
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .grid {
      grid-template-columns: 1fr;
    }
    .addPhone {
      width: 100% !important;
    }
  }
}
