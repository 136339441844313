@import '../../assets/scss/variables';

.chatDetails {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 30px 20px;
  .chatHeader {
    border-left: 3px solid $additional-orange;
    padding: 0px 28px;
  }
  .chatContent {
    margin-top: 30px;
    border-top: 1px solid $additional-light-grey-2;
    padding: 30px 0px;
    overflow: hidden;
    height: 500px;
    overflow-y: auto;
    .messagesing {
      float: left;
      clear: both;
      width: 80%;
      .userInfo {
        display: flex;
        .userImg {
          width: 52px;
          height: 51px;
          border-radius: 52px;
        }
        .message {
          margin: 13px 21px;

          .createdAt {
            color: $additional-grey-3;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
    }

    .myMessagesing {
      float: right;
      text-align: right;
      .userInfo {
        display: block;
      }
    }
  }

  .messageLabel {
    margin: 20px 0px 11px 0px;
  }

  .messageContent {
    display: flex;
    .sendButton {
      border: none;
      background: none !important;
      width: 32px !important;
      height: 32px;
      margin-top: 16px;
      &:hover {
        background: none !important;
      }

      .sendMessage {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .chatDetails .chatContent .messagesing {
    .userInfo .message .userName{
      font-size: 14px;
    }
  }
}
