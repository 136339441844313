@import '../../assets/scss/variables';

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cardText {
    margin-right: 20px;
    p:first-child {
      margin-bottom: 9px;
    }
  }

  .cardButton {
    width: 240px !important;
  }
}

@media only screen and (max-width: $md) {
  .card {
    display: block;
    text-align: center;
    .cardButton {
      margin-top: 16px;
    }
  }
}
