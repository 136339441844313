@import '../../../../assets/scss/variables';

.primaryBankAccount {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  width: 100%;
  margin-bottom: 30px;
  .title {
    margin-bottom: 10px;
  }
  .desc {
    margin-bottom: 30px;
    color: $additional-grey-2;
  }
  .input {
    width: 100%;
  }
}
