@import 'src/assets/scss/variables';

.card {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  .imageWrap {
    margin-right: 16px;
    .image {
      width: 128px;
      height: 128px;
      border-radius: 16px;
    }
  }
  .wrap {
    display: flex;
    width: 100%;
    .info {
      .propertyName {
        white-space: pre-wrap;
        word-break: break-all;
      }
      .address {
        margin: 8px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .locationIcon {
          min-width: 16px;
          width: 16px;
          height: 16px;
          margin-right: 8px;
          fill: $current-red;
        }
      }
      .units {
        display: flex;
        .line {
          width: 2px;
          height: 25px;
          background-color: $primary-black;
          margin: 0 10px;
        }
      }
    }
  }
}

.buttons {
  min-width: 210px;
  display: flex;
  margin-right: 7px;
  .edit {
    max-width: 62px;
    margin-left: 7px;
  }
}

.content {
  margin-top: 17px;
}

.extendableContent {
  display: grid;
  grid-template-columns: 0.5fr 3fr;
  .infoWrap {
    border: 1px solid $additional-light-grey-4;
    border-radius: 20px;
    padding: 15px 20px 15px 25px;
    margin-top: 8px;
    display: grid;
    grid-template-columns: 1fr 127px;
    justify-content: space-between;
    align-items: flex-end;
    .info {
      .monthlyRent {
        display: flex;
        color: $additional-grey-2;
        margin: 6px 0;
        .rent {
          color: $primary-orange;
        }
      }
      .type {
        margin: 10px 0 15px 0;
        color: $additional-grey-2;
      }
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .boxIcon {
      width: 24px;
      height: 24px;
      min-height: 24px;
      margin: 8px 0 8px 4px;
      fill: $additional-white;
    }
    .line {
      width: 2px;
      height: 100%;
      background-color: $additional-light-grey-4;
    }
    .hideLine {
      background-color: $additional-white;
    }
  }
}

.status {
  color: $primary-orange;
}

@media only screen and (max-width: $md) {
  .card {
    display: grid;
    grid-template-columns: 1fr;
  }

  .wrap {
    padding: 10px;
  }
  .extendableContent .infoWrap {
    grid-template-columns: 1fr;
    margin-right: 7px;
  }
}

@media only screen and (max-width: $sm) {
  .card .wrap {
    display: block;
  }
}
