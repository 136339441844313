@import 'src/assets/scss/variables';

.listingsLayout {
  width: 100%;
  .container {
    display: flex;
    width: 100%;
    max-width: 1065px;
    margin: 0 auto;
    padding: 0 16px;
  }
  .header {
    min-height: 70px;
    background: $primary-blue;
    width: 100%;
    display: flex;
    align-items: center;
    .logo {
      width: 100%;
      max-width: 1065px;
      margin: 0 auto;
      padding: 0 16px;
    }
  }
}

@media only screen and (max-width: $md) {
  .listingsLayout .header {
    display: none;
  }
}
