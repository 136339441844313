@import './MenuLink.variables';

.link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: inherit;
  text-decoration: none;
  height: 100%;
  width: 100%;
  color: Var(color);
  padding: Var(padding);

  &:visited {
    color: Var(visited-color);
  }

  &.active {
    background-color: Var(selected-bg);
    color: Var(selected-color);
    cursor: pointer;
  }

  &:hover {
    background-color: Var(hover-bg);
    color: Var(hover-color);
    cursor: pointer;
  }

  &:focus {
    background-color: Var(focused-bg);
    color: Var(focused-color);
  }

  &:active {
    color: Var(active-color);
    background-color: Var(active-bg);
  }

  &.withoutHover {
    background-color: unset;
  }
}
