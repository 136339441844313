@import 'src/assets/scss/variables';

.applicants {
  border-radius: 20px;
  margin-bottom: 30px;
  .titleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 28px;

    .title {
    margin-bottom: 40px;
    }
    .incompleteButton {
      margin-top: 20px;
      margin-right: 50px;
      min-width: 220px;
    }
  }
  .table {
    tr:hover {
      background: transparent;
      cursor: auto;
      border-radius: 20px;
    }
    td {
      padding: 9px 18px;
    }
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $additional-white;
  border-radius: 20px;
  min-height: 700px;
  text-align: center;
}
.applicant {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: left;
  padding: 20px 12px;
  .firstName {
    white-space: pre-wrap;
  }
}

@media only screen and (max-width: $sm) {
  .applicants {
    padding: 0px;
    .titleContent{
      display: block !important;
    }
  }
  .header {
    h3 {
      font-size: 20px;
    }
  }
  .applicantTabel {
    display: none;
  }
}
