@import 'src/assets/scss/variables';

.unitCard {
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 0;
  .status {
    display: flex;
    gap: 8px;
  }
  .info {
    color: $additional-grey-2;
    margin: 8px 0 12px 0;
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .editButton {
      max-width: 92px;
    }
  }
}

.orange {
  color: $primary-orange;
}

.inputBox {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-bottom: 20px;
  margin-top: 20px;
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
}

.buttonsWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .delete {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    color: $additional-light-grey-4;
    .label {
      border-bottom: 1px solid $additional-light-grey-4;
      line-height: 14px;
    }
    .trash {
      fill: $additional-white;
      width: 24px;
      height: 24px;
    }
  }

  .buttons {
    display: flex;
    gap: 11px;
  }
}

@media only screen and (max-width: $sm) {
  .inputBox {
    .grid {
      grid-template-columns: 1fr;
    }
  }
}
