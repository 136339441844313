@import 'src/assets/scss/variables';

.leaseDetail {
  .desc {
    margin: 17px 0 20px 0;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 20px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
  .groupInfo {
    margin-bottom: 20px;
    .description {
      margin-bottom: 19px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .leaseDetail .inputBox .grid {
    grid-template-columns: 1fr;
  }
}
