@import '../../../../assets/scss/variables';

.container {
  display: flex;
  flex-direction: column;
}
.banner {
  background-color: $additional-light-grey-4;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
  margin-bottom: 20px;
  .imgBox {
    width: 128px;
    height: 128px;
    background-color: $additional-light-grey-2;
    position: relative;
    border-radius: 32px;
    margin-right: 20px;
    .logo {
      width: 33px;
      height: 23px;
      padding: 13px 8px;
      box-sizing: content-box;
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 26px);
      border: double $additional-light-grey-4 3px;
    }
  }
  .info {
    color: $additional-white;
    line-height: 46px;
    margin: 16px 0;
  }
  .address {
    color: $additional-white;
    .circle {
      width: 14px;
      height: 14px;
      border: 1px solid $additional-white;
      border-radius: 50%;
      display: inline-flex;
      position: relative;
      margin-right: 8px;
      .subCircle {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        position: absolute;
        left: 10%;
        top: -5px;
      }
    }
  }
  .details {
    display: inline-flex;
    color: $additional-white;
    margin-top: 20px;
    margin-bottom: 10px;
    .detailsBtn {
      border-radius: 4px !important;
      padding: 10px 40px;
      margin-left: 20px;
    }
  }
}
.congratulations {
  background-color: rgb(236, 236, 236);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  text-align: center;
  .registeredImg {
    width: 75px;
    margin: 0 auto;
  }
  .congratulationsTitle {
    color: $primary-blue;
    margin-top: 20px;
  }
  .congratulationsInfo {
    color: $primary-blue;
    margin-top: 20px;
  }
  .congratulationsText {
    color: $additional-grey-2;
    margin: 20px 0;
  }
}
