@import '../../../assets/scss/variables';

.container {
    .titleContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 28px;
    
        .title {
        margin-top: 20px;
        }
        .statusButton {
          margin-top: 20px;
          margin-right: 50px;
          min-width: 220px;
        }
      }
      .details {
        border-radius: 24px;
        background-color: $additional-white;
        padding: 0 20px;
      
        .personInfo {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $additional-light-grey-2;
          padding: 12px 0;
          &:last-child {
            border-bottom: none;
          }
      
          .title {
            margin: 8px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            .profile {
              width: 16px;
              height: 16px;
              margin-right: 8px;
              fill: $additional-grey-3;
            }
            .iconLight {
              fill: $additional-white;
              stroke: $additional-grey-3;
            }
          }
          .value {
            margin-top: 12px;
          }
        }
        .description {
            margin-top: 20px;
            .parking {
                margin-top: 15px;
            }
            .descriptionTitle {
                margin-top: 15px;
            }
            .descriptionBody {
                margin-top: 15px;
            }
        }

        .documents {
            display: flex;
            justify-content: space-between;
            padding: 20px 0px 10px 0px;
            margin-bottom: 20px;
            &:last-of-type {
              border-bottom: none;
            }
            .fileBox {
              display: grid;
              grid-template-columns: 1fr 1fr;
              direction: rtl;
            }
        
            .image {
              border: 1.4px solid $additional-light-grey-2;
              border-radius: 4px;
              display: flex;
              margin-left: 17px;
              position: relative;
              object-fit: contain;
              width: 230px;
              height: 140px;
              margin-bottom: 16px;
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
        
            .title {
              margin: 8px 0;
              display: flex;
              align-items: center;
              cursor: pointer;
              .profile {
                width: 16px;
                height: 16px;
                margin-right: 8px;
                fill: $additional-grey-3;
                fill: $additional-white;
                stroke: $additional-grey-3;
              }
            }
          }
      }
      
}

.button {
  margin: 20px 0;
  padding-bottom: 20px;
}

.managerWrap {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;
  margin-bottom: 20px;
  .managerTitle {
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .autocomplete {
    padding-bottom: 25px;
  }
}