@import 'src/assets/scss/variables';

.reactCodeInput {
  display: flex !important;
  justify-content: space-between;
  align-items: center;

  :disabled {
    background-color: $additional-light-grey-1 !important;
    border: 1px solid $additional-light-grey-2 !important;
  }

  :focus {
    border-color: $primary-blue !important;
    outline: none !important;
    color: $primary-blue !important;
  }
}

@media only screen and (max-width: $md) {
  .reactCodeInput input {
    width: 44px !important;
    height: 44px !important;
    margin-right: 4px !important;
  }
}

@media only screen and (max-width: $sm) {
  .reactCodeInput input {
    width: 42px !important;
    height: 42px !important;
  }
}
