@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;
  .personInfo {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-bottom: -3px;
        fill: $additional-grey-3;
      }
      .iconLight {
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
    .mnorsAdults {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
    }
  }

  .prevAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid $additional-light-grey-2;
    .prevAddressRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .value {
        margin-left: 26px;
      }
    }
    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 18px;
        height: 18px;
        margin-right: 8px;
        margin-bottom: -3px;
        fill: $additional-grey-3;
      }
      .iconLight {
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
  }
}
.borderButtonLine {
  border-bottom: 1px solid $additional-light-grey-2;
}
