@import '../../../../assets/scss/variables';

.rentalOwner {
  background: $additional-white;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  .title {
    margin-bottom: 10px;
  }
  .desc {
    color: $additional-grey-2;
    margin-bottom: 20px;
  }
  .items {
    width: 100%;
    position: relative;
    .closeIcon {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 0;
      top: -20px;
      fill: $additional-grey-3;
      cursor: pointer;
    }
    .email {
      margin-bottom: 20px;
    }
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 50px;
      .iconsWrap {
        display: flex;
        align-items: center;
        color: $additional-grey-3;
        .icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
          fill: $additional-grey-3;
        }
        .phoneIcon {
          stroke: $additional-grey-3;
          fill: $additional-white;
        }
      }
    }
    .lineButtom {
      border-bottom: 1px solid $additional-light-grey-4;
    }
    .name {
      margin-top: 30px;
    }
    .phone {
      border-bottom: 1px solid $primary-blue;
      padding: 15px 0 30px;
    }
  }
}

@media only screen and (max-width: $md) {
  .rentalOwner {
    .items {
      .label{
        display: block;
        padding: 10px;

        .value{
          word-wrap: break-word;
          margin: 5px 0px 5px 25px;
        }
      }
    }
  }
}
