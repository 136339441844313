.userMenuItem {
  display: flex;

  .userMenuLink {
    padding: 0 10px;
  }
  .details {
    flex: 1;
    margin-left: 10px;
    width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
  }

  .label {
    //@include ellipsis(100%);
    display: inline-block;
    transition: opacity 0.3s;
    opacity: 1;

    &.collapsed {
      margin: 0;
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  .status {
    font-size: 12px;
    color: aquamarine;
  }
}

.withRtl {
  .details {
    margin-right: 10px;
    margin-left: 0;
  }
}
