@import '../../../assets/scss/variables';

.alertCard {
  width: 100%;
  border-radius: 20px;
  background-color: $additional-white;
  padding: 16px;
  display: flex;
  .title {
    font-size: 20px;
    line-height: 36px;
    color: $additional-grey-1;
  }
  .close {
    max-width: 15px;
    max-height: 15px;
    fill: $additional-grey-1;
    cursor: pointer;
    margin-left: auto;
  }
}

.line {
  min-width: 6px;
  max-height: 100%;
  margin-right: 27px;
  min-height: 36px;
  margin-left: 3px;
  border-radius: 6px;
}

.error {
  background-color: $additional-red;
}

.info {
  background-color: $secondary-hover-or;
}

@media only screen and (max-width: $sm) {
  .alertCard .title {
    font-size: 12px;
    line-height: 16px;
  }
}
