@import 'src/assets/scss/variables';
.sidebarFooter {
  padding: 10px;
  display: block;
  margin-top: auto;
  .line {
    width: 100%;
    height: 1px;
    background-color: $additional-light-grey-5;
    margin-bottom: 4px;
  }
}
