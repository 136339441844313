@import 'src/assets/scss/variables';

.content {
  padding: 0px 20px 20px 20px !important;
  .image {
    max-width: 500px;
  }

  .document{
    max-width: 500px;
  }
}

.close {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 5px;
}

@media only screen and (max-width: $sm) {
    .content {
        .image {
          max-width: 300px;
        }
      }
  }
