@import 'src/assets/scss/variables';

.details {
    border-radius: 24px;
    background-color: $additional-white;
    padding: 0 20px;

    .creditDetails{
        border-left: 3px solid $additional-orange;
        margin: 20px 0px;
        padding: 10px;
        background-color: $secondary-background;
    }

    .info {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $additional-light-grey-2;
      padding: 12px 0;
      &:last-child {
        border-bottom: none;
      }
  
      .title {
        margin: 8px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  