@import 'src/assets/scss/variables';

.sucess{
  background: $additional-white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  .title{
    color: $primary-orange;
  }
  .note{
    margin-bottom: 20px;
  }
}

.methodsWrap{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 20px;
  .methods {
    border: 1px solid $additional-grey-3;
    padding: 10px;
    background: $additional-white;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    .icon{
      width: 24px;
      height: 24px;
      fill: $additional-white;
      &:hover{
        fill: $additional-grey-4 !important;
      }
    }
    .brand{
      text-transform: capitalize;
    }
    .info{
      margin-left: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .image {
      width: 110px;
      height: 70px;
      border-radius: 8px;
    }
  }
}

.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
