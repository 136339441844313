@import '../../assets/scss/variables';

.properties {
  .action {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;
  }
  .noFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $additional-white;
    border-radius: 20px;
    min-height: 30vh;
    .desc {
      color: $primary-black;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: $md) {
  .properties {
    margin-top: 35px;
    .action {
      H3 {
        font-size: 22px;
      }
      .createPropertyBtn {
        width: 130px;
      }
    }
  }
}
