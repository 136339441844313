@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;

  .address {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 12px 0;
    .date {
      margin-left: 23px;
    }
  }

  .prevAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid $additional-light-grey-2;
    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
    }
    .prevAddressRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .date {
        margin-left: 23px;
      }
    }
  }

  .refAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 0;
    .refAddressRow {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      .nameValue {
        margin-left: 22px;
      }
    }

    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
      .iconLight {
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
  }

  .sectionMain {
    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
      .iconLight {
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
  }
}
