@import 'src/assets/scss/variables';

.chart {
  border: 1px solid var(--additional-light-grey-5);
  border-radius: 24px;
  background: var(--additional-white);
  overflow: hidden;
  .tabs {
    margin-left: 20% !important;
    .indicator {
      background-color: $primary-blue;
    }
  }
}

@media only screen and (max-width: $md) {
  .chart:first-child {
    margin-bottom: 20px;
  }
}
