@import '../../../assets/scss/variables';

.createProperty {
  .title {
    margin-bottom: 40px;
  }

  .create {
    margin-top: 30px;
  }
  .units {
    margin-bottom: 20px;
  }
  .addUnits {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: $md) {
  .createProperty {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }
}
