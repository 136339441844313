@import 'src/assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 0 0 10px 0;
  }
  .desc {
    padding: 20px 0;
    .signIn {
      color: $primary-blue;
      margin-left: 10px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .button {
      margin-top: 20px;
    }

    .termsAndConditionsBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 14px;
      margin-top: 14px;

      a {
        text-decoration: none;
        color: #000000;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.disabled {
  color:  $additional-grey-1;
}

.redText {
  color: red !important;
}
