@import 'src/assets/scss/variables';

.userCard {
  padding: 23px 20px;
  border: 1px solid $additional-light-grey-5;
  border-radius: 10px;
  display: flex;
  background: $additional-white;
  .avatar {
    height: 115px;
    width: 115px;
    background: $primary-blue;
    margin-right: 16px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
    margin-left: 10px;
    .email {
      word-break: break-all;
    }
  }
  .type {
    color: #ff8730;
  }
}

@media only screen and (max-width: $md) {
  .userCard {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: $sm) {
  .userCard {
    display: block;
    text-align: center;
    .avatar {
      height: 85px;
      width: 85px;
      margin: auto;
      display: flex;
    }
  }
}
