@import 'src/assets/scss/variables';

.card {
  display: grid;
  grid-template-columns: 85% 10% 5%;
  .info {
    padding-left: 10px;
    .rightSide {
      cursor: pointer;
      display: flex;
      .notificationIcon {
        width: 24px;
        height: 30px;
        fill: $additional-white;
        margin-right: 10px;
      }
      p {
        font-size: 20px;
        font-weight: 600;
      }
    }
    .description {
      display: flex;
      .readMore {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: $primary-orange;
        cursor: pointer;
      }
    }
    .readStyle{
      color: $read;
    }
  }
  .action {
    display: grid;
    text-align: end;
    .trash {
      width: 24px;
      height: 30px;
      fill: $additional-white;
      cursor: pointer;
    }
    .ribbon {
      width: 24px;
      height: 30px;
      padding-left: 4px;
      fill: $additional-white;
      stroke: $additional-light-grey-3;
      cursor: pointer;
    }
    .yellowRibbon {
      width: 24px;
      height: 30px;
      padding-left: 4px;
      fill: $primary-orange;
      stroke: $primary-orange;
    }
  }
}

.readCard {
  background-color: rgb(255 255 255 / 35%);
  h5 {
    font-weight: 400 !important;
  }
}
@media only screen and (max-width: $sm) {
  .card {
    grid-template-columns: 80% 15% 5%;
    .rightSide H5 {
      font-size: 14px;
    }
    .info {
      .description {
        display: block;
      }
      p {
        font-size: 12px;
      }
    }
  }
}
