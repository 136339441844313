@import '../../../assets/scss/variables';

.createMaintenence {
  .title {
    margin-bottom: 20px;
  }

  .container{
    background: $additional-white;
    border-radius: 20px;
    padding: 30px;

  .descTitle {
    margin-bottom: 20px;
  }

  .desc {
    margin: 20px 0;
  }

  .availableDateWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    .availableDate {
      width: 380px;
    }
    .checkboxGrid {
      display: flex;
      justify-content: inherit;
      margin-left: 30px;
      margin-top: 5px;
      .anyTimeTitle {
        margin-left: 15px;
        margin-top: 2px;
      }
    }
  }

  .documentBox {
    padding: 40px 30px 0 0;

    .info {
      margin-bottom: 30px;
    }

    .documentUploaderTitle {
      margin-bottom: 15px;
    }
  }
  .btn {
    margin-top: 20px;
  }
  }

  .create {
    margin-top: 30px;
  }
  .units {
    margin-bottom: 20px;
  }
  .addUnits {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.managerWrap {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;
  margin-bottom: 20px;
  .managerTitle {
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .autocomplete {
    padding-bottom: 25px;
  }
}

@media only screen and (max-width: $md) {
  .createMaintenence {
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 30px;
    }
  }
}
