@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .incomeTitle {
    padding: 10px 30px;
  }
  .line {
    width: 100%;
    border: 1px solid $additional-light-grey-2;
    margin-bottom: 0;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .addIncomebtn {
      width: 49% !important;
      height: 47px !important;
      padding: 16px 48px !important;
      border-radius: 6px !important;
    }
    .previousIncome {
      margin-top: -30px;
      .incomeWrap {
        margin: 30px 0;
        .closeBox {
          position: relative;
          top: 10px;
          margin-bottom: 38px;
          padding-top: 50px;
          border-top: 1px solid $additional-light-grey-2;
          .closeIcon {
            position: absolute;
            z-index: 999;
            right: 0;
            width: 14px;
            height: 14px;
            top: 28px;
            fill: $additional-light-grey-3;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $md) {
  .container .inputBox {
    .grid {
      grid-template-columns: 1fr;
      height: 130px;
    }
    .addIncomebtn {
      width: 100% !important;
    }
  }
}
