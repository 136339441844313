@import '../../../assets/scss/variables';

.table {
  border-spacing: 0;
  background: $additional-white;
  margin-block: 15px;
  border-radius: 10px;
  block-size: fit-content;
  table-layout: fixed;
  inline-size: 100%;
  tbody {
    tr:hover {
      background: $additional-light-grey-2;
      cursor: pointer;
    }
  }
}

.table tr:last-child td {
  //border-block-end: 0;
}
.table th,
.table td {
  margin: 0;
  padding-inline: 20px;
  padding-block: 10px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
  border-block-end: 1px solid rgba(109, 132, 155, 0.2);
}
.table th {
  text-align: start;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 400;
  padding-block: 15px;
  span {
    position: absolute;
  }
}
.table tr {
  //padding-block-start: 10px;
}
.table td {
  text-align: start;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table th:last-child,
.table td:last-child {
  border-inline-end: 0;
}

.table th::before {
  position: absolute;
  inset-inline-end: 15px;
  inset-block-start: 16px;
  content: '';
  inline-size: 0;
  block-size: 0;
  border-inline: 5px solid transparent;
}
.lg {
  td {
    block-size: 60px;
  }
}

.md {
  td {
    block-size: 45px;
  }
}

.sm {
  inline-size: 960px;
  border: 1px solid #6d849b33;
  td {
    block-size: 35px;
  }
  th,
  td {
    padding: 5px;
    font-size: 12px;
  }
}

.paginationWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  font-size: 12px;
  .totalItems {
    color: yellow;
    margin-inline-start: 4px;
  }
  .records {
    color: gold;
  }
}

@media only screen and (max-width: $sm) {
  .table {
    overflow-x: auto;
    display: block;
  }
}
