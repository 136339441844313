@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  padding: 20px;
  .title {
    font-weight: bold;
    margin: 0 0 20px 17px;
  }
}

.alertContanier {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .alertCard {
    max-width: 1000px;
    max-height: 775px;
    background-color: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .alertHeader {
      width: 100%;
      border-bottom: 1px solid $additional-light-grey-2;
      .header {
        padding: 24px 40px;
        display: flex;
        justify-content: space-between;
        line-height: normal !important;
        .img {
          line-height: normal !important;
        }
        span {
          font-size: 24px;
        }
      }
    }
    .alertContent {
      padding: 60px 40px 30px;
      .text {
        padding-bottom: 14px;
      }
      .title {
        color: $primary-blue;
      }
    }
    .alertActions {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 40px;
    }
    .alertBtn {
      padding-top: 50px;
    }
  }
}

@media only screen and (max-width: $md) {
  .alertContanier {
    .alertCard {
      max-height: 100%;
      .alertContent {
        padding: 20px 40px 10px;
      }
      .alertActions {
        padding: 0px 40px;
      }
      .alertBtn {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .alertContanier {
    .alertCard {
      .alertContent{
        max-height: 500px;
        overflow-y: scroll;
      }
      .alertActions {
        padding: 20px 40px;
      }
    }
  }
}
