@import '../../../../assets/scss/variables';

.lateFee {
  width: 100%;
  background: $additional-white;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 30px 24px;
  box-shadow: 0 6px 58px 0 rgba(196, 203, 214, 0.102);
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .lateFee .inputBox .grid {
    grid-template-columns: 1fr;
  }
}
