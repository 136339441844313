@import '../../../assets/scss/variables';

.table {
  border-spacing: 0;
  margin-block: 15px;
  border-radius: 10px;
  block-size: fit-content;
  table-layout: fixed;
  margin-bottom: 0;
  inline-size: 100%;
  color: $additional-grey-1;

  tbody {
    tr {
      background: $additional-white;
      border-radius: 10px;
      min-height: 90px;
      margin-bottom: 20px;
      cursor: pointer;
      overflow: hidden;
    }
  }
}

.table thead tr {
  background: $additional-white;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
}
.table thead tr th {
  background: url(line.png) center right no-repeat;
  display: flex;
  justify-content: space-between;
}
.arrowIcon {
  width: 24px;
  height: 24px;
  transform: rotate(360deg);
}
.isSortedIcon {
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
}
.table tbody tr td {
  background: url(lineBig.png) center right no-repeat;
  word-wrap: break-word;
  word-break: break-all;
  text-wrap: revert;
  display: flex;
  align-items: center;
}

.table tbody tr td:last-child {
  background: inherit;
}

.table thead tr th:last-child {
  background: inherit;
}

.headerLabel {
  color: $additional-grey-3;
  display: flex;
  align-items: center;
}

.table tr:last-child td {
  //border-block-end: 0;
}
.table th,
.table td {
  margin: 0;
  //padding-inline: 20px;
  //padding-block: 10px;
  padding: 10px 20px;
  position: relative;
  font-size: 14px;
  line-height: 16px;
}
.table th {
  text-align: start;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 400;
  padding-block: 10px;
  span {
    display: flex;
    align-items: center;
    //position: absolute;
    // right: 0;
  }
}

.table td {
  text-align: start;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.table th:last-child,
.table td:last-child {
  border-inline-end: 0;
}

.lg {
  td {
    block-size: 60px;
  }
}

.md {
  td {
    block-size: 45px;
  }
}

.sm {
  inline-size: 960px;
  border: 1px solid #6d849b33;
  td {
    block-size: 35px;
  }
  th,
  td {
    padding: 5px;
    font-size: 12px;
  }
}

@media only screen and (max-width: $sm) {
  .table {
    overflow-x: auto;
    display: block;
  }
}
