@import '../../assets/scss/variables';

.header {
  background-color: inherit;
  display: flex;
  justify-content: flex-end;
  height: 35px;
  .menuButton {
    position: relative;
    width: 35px;
    height: 35px;
    margin-left: 12px;
    background-color: $additional-white !important;
    .menuIcon {
      width: 20px;
      height: 20px;
    }
    .unReadNotifications{
      position: absolute;
      width: 7px;
      height: 7px;
      background-color: $current-red;
      border-radius: 5px;
      top: 5px;
      right: 10px;
    }
  }
  .fullNameButton {
    font-size: 16px;
    display: flex;
    align-items: center;
    margin-left: 12px;
    .firstCharacter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: $additional-white;
      color:  $primary-blue;
    }
  }
  .button {
    background-color: $primary-blue;
    color: $primary-blue;
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
}

.content {
  min-width: 210px;
  background: $additional-white;
  .link {
    text-decoration: none;
    color: $primary-black;
  }
  .active {
    color: $primary-blue;
  }
}
