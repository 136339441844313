@import 'src/assets/scss/variables';

.property {
  border: 1px solid $additional-light-grey-5;
  border-radius: 20px;
  padding: 6px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  background: $additional-white;
  .galleryImage {
    width: 128px;
    height: 128px;
    border-radius: 16px;
  }
  .info {
    height: 100%;
    margin-left: 19px;
    margin-bottom: auto;
    width: 100%;
    .name {
      word-break: break-all;
    }
    .address {
      margin: 8px 0 23px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .locationIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $current-red;
      }
    }
    .monthlyRent {
      color: $primary-orange;
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 8px;
    }
    .seeDetails {
      max-width: 112px;
      margin-right: 18px;
    }
  }
}

@media only screen and (max-width: $sm) {
  .property {
    display: block;
    padding: 10px;
    .info .monthlyRent {
      display: block;
    }
    .seeDetails {
      display: flex;
      margin: auto;
    }
  }
}
