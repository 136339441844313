@import 'src/assets/scss/variables';

.checkbox {
  &:global(.MuiButtonBase-root.MuiCheckbox-root) {
    padding: 3px;
  }

  .checked {
    fill: $primary-blue;
    width: 20px;
    height: 20px;
  }

  .unchecked {
    fill: $additional-grey-3;
    width: 20px;
    height: 20px;
  }
}
