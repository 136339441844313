@import 'src/assets/scss/variables';

.autocomplete {
  background: $additional-light-grey-1;
  border: none;
  border-radius: 4px;
}

.noOption {
  cursor: pointer;
  padding: 0;
}

.item {
  background: red;
  &:hover {
    background: red !important;
  }
}
