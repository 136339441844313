@import 'src/assets/scss/variables';

.viewProperty {
  display: flex;
  flex-direction: column;
  .container {
    background: $additional-white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 20px;
    .title {
      margin-bottom: 20px;
    }
    .otherInfo {
      width: 100%;
      height: 60px;
      border: 1px solid $additional-light-grey-2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      border-radius: 10px;
      padding: 0 24px;
      .label {
        color: $additional-grey-3;
      }
    }
    .items {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      .item {
        font-size: 13px;
        line-height: 18px;
        padding: 7px 10px;
        border-radius: 10px;
        background: $secondary-background;
      }
    }
  }
  .utilities {
    display: flex;
    flex-direction: column;
    .utiliti {
      border: 1px solid $additional-light-grey-2;
      border-radius: 10px;
      padding: 10px;
      margin-bottom: 10px;
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .iconWrap {
        display: flex;
        align-items: center;
        .name {
          word-break: break-all;
          margin-right: 10px;
        }
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $additional-light-grey-2;
          height: 48px;
          min-width: 48px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .untilitiIcon {
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .gallery {
    background: $additional-white;
    padding: 20px 20px 30px 20px;
    margin-top: 20px;
    border-radius: 20px;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address {
        margin: 8px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        .locationIcon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          fill: $current-red;
        }
      }
      .editButton {
        max-width: 120px;
      }
    }
  }
}

.propertyName {
  word-break: break-all;
  margin-right: 20px !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: $additional-white;
  border-radius: 20px;
  margin: 20px 0 40px 0;
  padding: 33px 20px;
  gap: 24px;
}

@media only screen and (max-width: $md) {
  .grid {
    display: block;
  }
}

@media only screen and (max-width: $sm) {
  .viewProperty .gallery .title {
    display: grid;
  }
}

@media only screen and (max-width: $xs) {
  .otherInfo {
    height: 100px !important;
  }
}