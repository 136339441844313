@import 'src/assets/scss/variables';

.filter {
  width: 100%;
}

.options {
  background: $additional-white;
  box-shadow: 0 6px 58px 0 #c4cbd61f;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 19px;
  padding: 20px;
  .peats {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $additional-grey-2;
  }
  .tabs {
    display: flex;
    border: 1px solid $additional-light-grey-2;
    border-radius: 8px;
    overflow: hidden;
    .tab {
      height: 40px;
      width: 103px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .activeTab {
      background: rgba(var(--secondary-hover-bl),1);
      color: $additional-white;
    }
    .house {
      border-left: 1px solid $additional-light-grey-2;
      border-right: 1px solid $additional-light-grey-2;
    }
  }
}
