@import 'src/assets/scss/variables';

.container {
  margin-top: 30px;
  height: 800px;
  // overflow: auto;
  .info {
    background: $additional-white;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 30px;
    .desc {
      color: $additional-grey-2;
      margin: 10px 0 30px 0;
    }
    .fee {
      color: $primary-orange;
      font-size: 18px;
      margin-top: 30px;
    }
    .accordion {
      margin-bottom: 12px;
    }
  }
}
