@import 'src/assets/scss/variables';

.accessDenied {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
  background: $additional-white;
  border-radius: 20px;
}
