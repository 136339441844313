@import 'src/assets/scss/variables';

.menuItem {
  width: 100%;
  border-radius: 8px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 2px;
  font-size: 14px;

  &:hover {
    background-color: rgba($secondary-hover-bl,0.1);
    .iconElement {
      stroke: $primary-blue;
    }
    .label {
      color: $primary-blue;
    }
  }

  .menu {
    display: flex;
    padding: 0 12px;
    width: 100%;
  }

  img {
    max-width: 100%;
  }

  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }

  .iconElement {
    display: flex;
    align-items: center;
    stroke: $additional-light-grey-3;
    fill: $additional-white;
    width: 30px;
    height: 30px;
    justify-content: center;
    margin-left: 4px;
    &.selected {
      stroke: $primary-blue;
    }
  }
  .elem {
    display: flex;
    align-items: center;
  }
  .label {
    margin-inline-start: 12px;
    display: inline-block;
    transition: opacity 0.3s;
    opacity: 1;
    color: $additional-grey-2;
    white-space: nowrap;
  }
  .isActiveLabel {
    color: $primary-blue;
  }
  &.collapsed {
    overflow: hidden;
    .menu {
      padding: 0;
    }
  }
}

.isActiveMenu {
  background-color: rgba($primary-blue-1, 0.1);
  color: $additional-white;
}
