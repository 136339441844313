@import 'src/assets/scss/variables';

.card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cardText {
    margin-right: 20px;
    p:first-child {
      margin-bottom: 9px;
    }
  }
}

.userInfo {
  margin-bottom: 27px;
  h4 {
    margin-bottom: 17px;
  }
}

.chartContent {
  display: grid;
  column-gap: 38px;
  width: auto;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 24px;
  width: 100%;
}

@media only screen and (max-width: $md) {
  .chartContent {
    display: block;
  }
}

@media only screen and (max-width: $sm) {
  .card {
    display: block;
    text-align: center;
    .cardText {
      margin-bottom: 20px;
    }
  }
}
