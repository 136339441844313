@import 'src/assets/scss/variables';

.googleMap {
  height: 50vh;
  .marker {
    width: fit-content;
    position: relative;
  }
  .markerActive {
    width: 30px;
    height: 40px;
    position: relative;
  }
  .marker {
    width: 30px;
    height: 40px;
    position: relative;
    bottom: 30px;
  }
}

.info {
  background-color: $additional-white;
  position: absolute;
  width: fit-content;
  min-width: 400px;

  .header {
    height: 45px;
    border-bottom: 1px solid $additional-light-grey-3;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
  .content {
    padding: 12px 16px;
    .address {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      .locationIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
