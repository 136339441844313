@import 'src/assets/scss/variables';

.notifications {
  .title {
    margin-bottom: 20px;
  }
  .dateText {
    color: $secondary-hover-or;
    padding: 10px 30px;
  }

  .emptyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $additional-white;
    border-radius: 20px;
    min-height: 700px;
    text-align: center;
  }
}
