@import '../../../assets/scss/variables';

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $additional-grey-3;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
}
