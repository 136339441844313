@import '../../../assets/scss/variables';

.accordion {
  box-shadow: none !important;
  border: 2px solid $additional-light-grey-2;
  border-radius: 10px !important;
  .title {
    color: $additional-grey-1;
  }
}

.openAccordion {
  border: 2px solid $secondary-hover-bl !important;
}
