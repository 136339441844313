.slider {
  width: 100%;
  //max-width: 950px;
  margin-right: 120px;
  padding-bottom: 120px;
}

:global(.image-gallery-thumbnails) {
  width: 100%;
  overflow: auto !important;
  position: absolute;
}

@media screen and (max-width: 1023px) {
  .slider {
    margin-bottom: 100px;
  }
}
