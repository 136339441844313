@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  margin-top: 30px;
  .title {
    padding: 30px 30px 10px 30px;
  }
  .desc {
    padding: 0 30px 2px 30px;
  }
  .petsTitle {
    padding: 12px 30px 2px 30px;
  }
  .text {
    margin: 12px 30px 2px 30px;
  }
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
    .checkboxGrid {
      margin-left: 30px;
      margin-top: 15px;
    }
    .addPhone {
      width: 49% !important;
      height: 47px !important;
      padding: 16px 48px !important;
      border-radius: 6px !important;
    }
    .phones {
      .phonesWrap {
        margin: 30px 0;
        border-top: 1px solid $additional-light-grey-2;
        position: relative;
        padding-top: 50px;
        .closeBox {
          .titlePhone {
            margin: 30px 0 9px 0;
          }
        }
        .closeIcon {
          position: absolute;
          right: 0;
          width: 14px;
          height: 14px;
          top: 28px;
          fill: $additional-light-grey-3;
          cursor: pointer;
        }
      }
      .phonesWrap:first-of-type {
        padding-top: 0 !important;
      }
    }
  }

  .checkboxGrid {
    display: flex;
    align-items: flex-start;
    .petsText {
      color: $additional-grey-3;
      margin-left: 12px;
      max-width: 495px;
      padding-bottom: 30px;
    }
  }
  .addBtn {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }

  .petsGroup {
    margin: 15px 30px;
  }
}
