@import 'src/assets/scss/variables';

@media only screen and (max-width: $sm) {
  .mobileCard {
    display: block;
    margin-top: 10px;
    border: 1px solid $secondary-hover-bl;
    .cardItem {
      border-bottom: 1px solid $additional-grey-4;
      padding: 10px 10px;
      cursor: pointer;
      .cardItemtitle {
        color: $additional-grey-3;
      }
      .cardItemInfo {
        font-size: 15px;
        font-weight: 600;
        color: $additional-grey-1;
        line-height: 22px;
      }
      .cardItemName {
        display: list-item;
        margin-left: 15px;
        font-size: 15px;
        font-weight: 600;
        color: $additional-grey-1;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (min-width: $sm) {
  .mobileCard {
    display: none;
  }
}
