@import 'src/assets/scss/variables';

.sidebarHeader {
  margin-bottom: 16px;
  width: 100%;
  color: $additional-grey-2;
  padding: 10px 10px 0 10px;
}

.toggle {
  display: flex;
  position: relative;
  align-items: center;
  .toggleIcon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid $additional-light-grey-3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    background-color: #ffffff;
    right: -26px;
    margin-top: 12px;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: $additional-light-grey-5;
    margin-top: 12px;
  }
  .collapsed {
    display: none;
  }
  .active {
    transform: rotate(-90deg) !important;
  }
}

.arrowIcon {
  transform: rotate(90deg);
  width: 24px;
  height: 24px;
}
