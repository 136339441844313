@import 'src/assets/scss/variables';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteToApplyModal {
  max-width: 573px;
  width: 100%;
  min-height: 450px;
  border-radius: 20px;
  background-color: $additional-white;
  &:focus {
    outline: none;
  }
  .close {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
  .header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    border-bottom: 1px solid $additional-light-grey-5;
  }
  .content {
    display: grid;
    grid-gap: 30px;
    padding: 40px 54px;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 13px;
  }
}
