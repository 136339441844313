@import 'src/assets/scss/variables';

.confirmAccount {
  padding-top: 13px;
  .boxGroup {
    width: 50%;
    .confirmCode {
      margin-bottom: 10px;
    }
    .codeInputBox {
      padding: 44px 0 20px;
    }
    .dontGetCodeBox {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: -2.5em;
      .resend {
        cursor: pointer;
      }
    }
  }
}
