@import 'assets/scss/variables';

.accordion{
  margin-bottom: 16px;
}

.container {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;

  .info {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 12px 0;
    &:last-child {
      border-bottom: none;
    }

    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .value {
      margin-top: 12px;
    }
  }
}
