@import 'src/assets/scss/variables';

.container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 30px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 100px;

  .card {
    position: relative;
  }

  .progressBarBox {
    // position: relative;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    .progressBarBoxIn {
      // position: fixed;
      background-color: $primary-blue;
      height: auto;
      min-width: 400px;
      padding: 40px 0 20px 16px;
      max-height: 100%;
      border-radius: 24px;
    }
    .logoWrap {
      border: 1px solid $additional-white;
      border-radius: 18px;
      margin: 0 17px 20px;
      padding: 20px;
      .logo {
        width: 91px;
        height: 21px;
      }
      
    }

    .title {
      color: $additional-white;
      margin-left: 20px;
      margin-bottom: 6px;
    }

    .step {
      color: $additional-white;
    }

    .activeStepLabel {
      color: $additional-white;
    }

    .passiveStepLabel {
      color: $additional-grey-3;
    }
    .stepLabel {
      color: $additional-white;
    }
  }

  .outlet {
    border-radius: 20px;
    background-color: $additional-white;
    .btn {
      padding: 30px 30px 20px 30px;
    }
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
  }
}

@media only screen and (max-width: $md) {
  .container {
    grid-template-columns: 1fr;
    padding: 0;

    .progressBarBox {
      width: 100%;
      padding-top: 0;

      .progressBarBoxIn {
        border-radius: 0 0 24px 24px;
        border-top: 2px solid $additional-white;
      }

      .logo {
        display: none;
      }
      .title {
        text-align: center;
        margin-left: 0 !important;
        padding-top: 20px;
      }
    }
  }
}

.done {
  grid-template-columns: auto;
}
