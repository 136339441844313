@import 'src/assets/scss/variables';

.container {
  .labelContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 28px;
    .buttons {
      min-width: 210px;
      display: flex;
      margin-right: 7px;
      .edit {
        margin-left: 7px;
      }
    }
  }
  .applicantDetails {
    background-color: $additional-white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 20px;
    .tabsWrap {
      padding-left: 0;
      margin-bottom: 40px;
      .tabs {
        .indicator {
          background-color: $primary-blue;
        }
      }
    }
    .accordion {
      margin-bottom: 16px;
      .personInfo {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $additional-light-grey-2;
        padding: 12px 0;
        .fullName {
          margin: 8px 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          .profile {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            fill: $additional-grey-3;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .container {
    .labelContent {
      display: block;
      .buttons {
        margin-top: 20px;
      }
    }
    .applicantDetails .tabsWrap {
      max-width: 320px;
    }
  }
}
