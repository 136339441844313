@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  // margin-top: 30px;
  padding: 0 20px;

  .personInfo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 12px 0;

    .image {
      height: 140px;
      width: 240px;
      border-radius: 10px;
      box-shadow: 2px 2px 10px $additional-light-grey-2;
      padding: 6px;
      margin-left: 10px;
    }

    .document {
      padding: 14px;
      border: 1px solid $additional-light-grey-2;
      border-radius: 10px;
      box-shadow: 2px 2px 10px $additional-light-grey-2;
      margin-bottom: 10px;
      .docIcon {
        padding: 10px;
        border-right: 1px solid $additional-light-grey-2;
      }
      .docInfo {
        padding: 10px;
      }
    }
  }

  .documents {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 20px 0px 10px 0px;
    &:last-of-type {
      border-bottom: none;
    }
    .fileBox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      direction: rtl;
    }
    .document {
      border-radius: 10px;
      display: flex;
      margin-left: 17px;
      position: relative;
      object-fit: contain;
      width: 230px;
      height: 170px;
      margin-bottom: 16px;
      background-color: rgba(33, 85, 163, 0.2);

      .pdfDocumentContainer {
        cursor: pointer;
        width: 230px;
        height: auto;
        max-width: 100%;
        max-height: 100%;

        .file {
          text-align: center;
          border-radius: 10px;
          width: 100% !important;
          height: 100% !important;
          overflow: hidden;
        }
        .fileBlock {
          width: 230px;
          height: 140px;
          border-radius: 10px;
          text-align: center;
        }

        .fileName {
          position: absolute;
          bottom: 0;
          background-color: $additional-white;
          border-radius: 10px;
          width: 100%;

          .docInfo {
            // padding: 10px;
            padding-top: 15px;
            text-align: center;
            width: 100%;
            height: 70px;
            flex: 0 0 170px;
            word-wrap: break-word;
            display: inline-block;
            font-size: small;
          }
        }
      }

      .docIcon {
        padding: 10px;
        border-right: 1px solid grey;
      }

      .close {
        width: 22px;
        height: 22px;
        position: absolute;
        right: -12px;
        top: -10px;
        cursor: pointer;
      }
    }

    .image {
      cursor: pointer;
      border: 1.4px solid $additional-light-grey-2;
      border-radius: 4px;
      display: flex;
      margin-left: 17px;
      position: relative;
      object-fit: contain;
      width: 230px;
      height: 140px;
      margin-bottom: 16px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.14);
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
  }
}

@media only screen and (max-width: $sm) {
  .documents {
    .image {
      width: 50px !important;
      height: 50px !important;
    }
    .fileBox img {
      width: 50px;
      height: 50px;
    }
  }
}
