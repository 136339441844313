@import '../../../../assets/scss/variables';

.itemSelector {
  margin-bottom: 30px;
  .desc {
    margin: 10px 0 40px 0;
    color: $additional-grey-2;
  }
  .items {
    margin-bottom: 10px;
    .item {
      margin: 0 8px 14px 0;
      cursor: pointer;
      color: $additional-grey-1;
      border-radius: 8px;
    }
    .active {
      color: $additional-white;
      background: $primary-orange;
    }
  }
}
