@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --primary-black: #222222;
  --primary-green: #28aa5e;
  --primary-blue: #142B6F;
  --primary-blue-rgb: #142B6F;
  --primary-orange: #FFBF01;

  --secondary-background: #f7f7f8;
  --secondary-hover-bl: #{HexToRGB(#142B6F)};
  --secondary-hover-or: #FFBF01;

  --additional-white: #ffffff;
  --additional-grey-1: #4d5567;
  --additional-grey-2: #717785;
  --additional-grey-3: #9499a4;
  --additional-grey-4: #e4e6e8;
  --additional-light-grey-1: #fcfcfc;
  --additional-light-grey-2: #edeef0;
  --additional-light-grey-3: #a6aab3;
  --additional-light-grey-4: #caccd1;
  --additional-light-grey-5: #e0e0e0;
  --additional-red: #f32424;
  --additional-orange: #FF8730;
  --current-red: #ef5151;
  --read: #4d5567ab;

  --neutral-900: #111827;

  --new: #{HexToRGB(#142B6F)};
  --incomplete: #{HexToRGB(#d353dc)};
  --stopped: #{HexToRGB(#d88422)};
  --pending: #{HexToRGB(#ffbd21)};
  --rejected: #{HexToRGB(#ff0900)};
  --active: #{HexToRGB(#00d097)};
  --unsigned: #{HexToRGB(#9499a4)};
  --flagged: #{HexToRGB(#403294)};
  --returned: #{HexToRGB(#a658b4)};
  --overdue: #{HexToRGB(#891b22)};

  --mb: 567;
  --md: 767;
  --lg: 1023;
  --xl: 1280;
}

$primary-green: var(--primary-green);
$green: var(--green);
$primary-black: var(--primary-black);
$primary-blue: var(--primary-blue);
$primary-blue-1: var(--primary-blue-rgb);
$primary-orange: var(--primary-orange);

$secondary-background: var(--secondary-background);
$secondary-hover-bl: var(--secondary-hover-bl);
$secondary-hover-or: var(--secondary-hover-or);

$additional-white: var(--additional-white);
$additional-grey-1: var(--additional-grey-1);
$additional-grey-2: var(--additional-grey-2);
$additional-grey-3: var(--additional-grey-3);
$additional-grey-4: var(--additional-grey-4);
$additional-light-grey-1: var(--additional-light-grey-1);
$additional-light-grey-2: var(--additional-light-grey-2);
$additional-light-grey-3: var(--additional-light-grey-3);
$additional-light-grey-4: var(--additional-light-grey-4);
$additional-light-grey-5: var(--additional-light-grey-5);
$additional-red: var(--additional-red);
$additional-orange: var(--additional-orange);
$current-red: var(--current-red);

$neutral-900: var(--neutral-900);
$read: var(--read);

$xs: 450px;
$sm: 600px;
$md: 900px;
$lg: 1200px;
$xl: 1536px;