@import 'src/assets/scss/variables';

.tenants {
  background: $additional-white;
  padding: 30px 24px;
  border-radius: 20px;
  margin-bottom: 20px;
  .action {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
