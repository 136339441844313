@import '../../assets/scss/variables';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.successRegisteredModal {
  max-width: 530px;
  width: 100%;
  min-height: 300px;
  background-color: $additional-white;
  &:focus {
    outline: none;
  }
  .close {
    cursor: pointer;
  }
  .header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 30px;
    border-bottom: 1px solid $additional-light-grey-5;
  }
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    .congratulations {
      font-size: 30px;
      color: $primary-blue;
      margin-bottom: 20px;
    }
    .description {
      padding: 0 60px;
      text-align: center;
      .email {
        color: $secondary-hover-or;
        border-bottom: 1px solid $secondary-hover-or;
        cursor: pointer;
      }
    }
  }
}
