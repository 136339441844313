@import '../../../assets/scss/variables';

.googleAddressBox {
  .inputBox {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
    }
  }
}

@media only screen and (max-width: $md) {
  .googleAddressBox .inputBox .grid {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
