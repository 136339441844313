@import 'assets/scss/variables';

.container {
  border-radius: 24px;
  background-color: $additional-white;
  padding: 0 20px;

  .personInfo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $additional-light-grey-2;
    padding: 12px 0;
    &:last-child {
      border-bottom: none;
    }

    .title {
      margin: 8px 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      .profile {
        width: 16px;
        height: 16px;
        margin-right: 8px;
        fill: $additional-grey-3;
      }
      .iconLight {
        fill: $additional-white;
        stroke: $additional-grey-3;
      }
    }
    .value {
      margin-top: 12px;
    }
  }
}

@media only screen and (max-width: $xs) {
  .personInfo {
    display: block !important;
  }
}