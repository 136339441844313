/*font-face*/

@font-face {
  font-family: montserrat-regular;
  src: url('../fonts/Montserrat/static/Montserrat-Regular.woff') format('woff'),
    url('../fonts/Montserrat/static/Montserrat-Regular.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: montserrat-medium;
  src: url('../fonts/Montserrat/static/Montserrat-Medium.woff') format('woff'),
    url('../fonts/Montserrat/static/Montserrat-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: montserrat-medium-black;
  src: url('../fonts/Montserrat/static/Montserrat-Medium.woff') format('woff'),
    url('../fonts/Montserrat/static/Montserrat-Medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: montserrat-black;
  src: url('../fonts/Montserrat/static/Montserrat-SemiBold.woff') format('woff'),
    url('../fonts/Montserrat/static/Montserrat-SemiBold.woff2') format('woff2');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: montserrat-bold;
  src: url('../fonts/Montserrat/static/Montserrat-Bold.woff') format('woff'),
    url('../fonts/Montserrat/static/Montserrat-Bold.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
}
